<template lang="html">
    <div class="loading-screen-wrapper" v-bind:class="{ hide: isHidden }">
      <div class="loader-bg"></div>
      <div class="loading-screen-icon">
        <div class="new-loader">
          <div role="document" class="modal-dialog" v-if="showSessionTimeout">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Session Invalid/Timed out</h5>
              </div>
              <div class="modal-body">
                <p>Your session is invalid/timed out</p>
                <p>You will be redirected to login in {{second}} second(S)</p>
              </div>
              <div class="modal-footer">
                <div class="btn btn-primary" @click="dispatchLogoutEvent">Login now</div>
              </div>
            </div>
          </div>
          <div role="document" class="modal-dialog" v-if="showSessionRemaining">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Session is Idle</h5>
              </div>
              <div class="modal-body">
                <p>Your session is idle</p>
                <p>You will be logged out in {{second}} second(S)</p>
              </div>
              <div class="modal-footer">
                <div class="btn btn-primary" @click="extendSession">Extend Session</div>
              </div>
            </div>
          </div>
          <div class="new-loader bg"></div>
        </div>
      </div>
    </div>
</template>

<script>
import {events,eventBus} from '../../service/event.service';
import * as UserApis from '@common/utils/api/masters/users';
import * as loaderService from '@common/service/loader.service';
import * as toasterService from '@common/service/toaster.service';

export default {
  name: "loader",
  props:[],
  data () {
    return {
     isHidden:true,
     showSessionTimeout: false,
     showSessionRemaining: false,
     second: 0,
     timerId: null,
     userActivityTimeout: null,
     resetTimeout: null,
     INACTIVE_USER_TIME_THRESHOLD: (30 * 60 * 1000) // 9 minutes
    }
  },
  created (){
    eventBus.on(events.sessionTimeOut, () => {
      this.isHidden = false;
      this.showSessionTimeout = true;
      this.showSessionRemaining = false;
      this.resetSeconds(5);
    });
    this.activateActivityTracker();
  },
  methods:{
    resetSeconds: function(sec){
      this.second = sec;
      this.resetTimeout = setInterval(()=>{
        if(this.second < 1){
          this.dispatchLogoutEvent();
        } else {
          this.second--;
        }
      }, 1000);
    },
    dispatchLogoutEvent: function(){
      eventBus.dispatch(events.logout);
    },
    activateActivityTracker: function() {
      window.addEventListener("mousemove", this.resetUserActivityTimeout.bind(this));
      window.addEventListener("scroll",  this.resetUserActivityTimeout.bind(this));
      window.addEventListener("keydown",  this.resetUserActivityTimeout.bind(this));
      window.addEventListener("resize",  this.resetUserActivityTimeout.bind(this));
      this.resetUserActivityTimeout();
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.sessionRemaining();
      }, this.INACTIVE_USER_TIME_THRESHOLD);
    },
    sessionRemaining: function() {
      this.isHidden = false;
      this.showSessionTimeout = false;
      this.showSessionRemaining = true;
      this.resetSeconds(60);      
    },
    extendSession: function(){
      loaderService.showLoader();
      UserApis.extendSession().then(() => {
        loaderService.hideLoader();
        toasterService.success("Session extended successfully");
        clearTimeout(this.resetTimeout);
        this.showSessionTimeout = false;
        this.showSessionRemaining = false;
        this.isHidden = true;
      });
    }
  }
}
</script>


<style lang="scss">
.loading-screen-wrapper {
    &.hide{
        display: none;
    }
  .loader-bg {
    z-index: 1000000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(59, 59, 60, 0.4);
  }
  .loading-screen-icon {
    z-index: 1000001;
    position: relative;
    height: 100%;
    width: 360px;
    margin: 0 auto;
    .loaderImg {
      width: calculateRem(67px);
      height: calculateRem(67px);
    }
    .new-loader{
      height: 200px;
      width: 360px;
      position: fixed;
      top: calc(100vh/2 - 150px);
      &.bg{
        background: transparent;
        background-position: -120px -200px;
        background-size: 600px 590px;
      }
    }
  }
}
</style>
