<template lang="html">
    <div class="b-address">
        <span><b>Select Node : </b></span>
        <CButton color="secondary" class="filter-btn" @click="onLocationTabClick(-1)">
            All
        </CButton> 
        <span v-for="(item, index) in selectedLocations" :key="item.value"> / 
        <CButton color="secondary" class="filter-btn"  @click="onLocationTabClick(index)">{{item.label}}</CButton>
        </span>
        <div class="select_location" v-if="locationList.length > 0"> / 
        <CSelect
            label=""
            placeholder="Select Location"
            v-model="serverLocation"
            :options="locationList"
            @change.native="onLocationSelect" 
            style="display: inline-block;"/>
        </div>
        <div class="select_location">
            <multiselect
                v-model="selectedNodes" 
                :options="nodeList"
                @input="onNodeSelect"
                label="name" 
                track-by="id"
                :searchable="true" 
                :close-on-select="false" 
                :show-labels="false"
                :multiple="true"
            ></multiselect>
        </div>
        <CButton color="primary" class="ml-2 btn-sm" @click="updateSearch()">
            <i class="fa fa-search"></i>
        </CButton>
    </div>
</template>

<script>
import * as UserApis from '@common/utils/api/masters/users';
import Multiselect from 'vue-multiselect'
export default {
    name: "BrdgeAddress",
    components: { Multiselect },
    props: {},
    data() {
        return {
            locationList: [],
            selectedLocations: [],
            locationList: [],
            selectedLocations: [],
            serverLocation: null,
            serverParentLocation: '',
            nodeList: [],
            selectedNode: null,
            selectedNodes: [],
        };
    },
    mounted() {
        this.getUserProfile();
    },
    watch: {},
    methods: {
        //User Drilldown For Locations
        getUserProfile: function() {
            console.log("Get User Profile Method:");
            var locations = this.getDrilldown();
            locations.forEach((element, index) => {
                let tempArr = {
                    label: element.name,
                    value: element.id,
                    type: 0
                }
                this.selectedLocations.push(tempArr);
            });
            this.selectedLocCount = this.selectedLocations.length;
            this.fetchLocations();
        },
        getDrilldown: function() {
            console.log("window.keycloakImplObj.userDetails.drilldown : ", window.keycloakImplObj);
            return window.keycloakImplObj.userDetails.drilldown;
        },
        //Location & Node Selection
        onLocationSelect: function(evt) {
            this.selectedNode = null;
            this.selectedNodes = [];
            if('any' == evt.target.value) {
                return;
            }
            for(let index = 0; index < this.locationList.length; ++index) {
                if(this.locationList[index].value == evt.target.value) {
                    this.selectedLocations.push(this.locationList[index]);
                    break;
                }
            }
            this.fetchLocations();
            this.serverLocation = this.selectedLocations[this.selectedLocations.length - 1].value;
            this.serverParentLocation = this.selectedLocations[this.selectedLocations.length - 2] ? this.selectedLocations[this.selectedLocations.length - 2].value : null;
            if(this.serverLocation != null){
                this.$emit("locationSelect", this.serverLocation);
            }
        },
        onNodeSelect: function() {
            this.selectedNode = null;
            if(this.selectedNodes.length == 0) {
                return;
            }
            //this.selectedNode = value.target.value;
            this.serverLocation = this.selectedLocations[this.selectedLocations.length - 1].value;
            let list = [];
            this.selectedNodes.forEach(element => {
                list.push(element.id); 
            });
            this.selectedNode = list.join(',')
            console.log("Node : ", this.selectedNodes);
            console.log("Location : ", this.serverLocation);
            console.log("Node : ", this.selectedNode);
        },
        fetchLocations: function() {
            let parentId = null;
            let locationParentId = null;
            let type = 0;
            if(this.selectedLocations.length > 0) {
                parentId = this.selectedLocations[this.selectedLocations.length - 1].value;
                locationParentId = this.selectedLocations[this.selectedLocations.length - 2] ? this.selectedLocations[this.selectedLocations.length - 2].value : null;
                type = this.selectedLocations[this.selectedLocations.length - 1].type;
            }
            this.serverLocation = parentId;
            this.serverParentLocation = locationParentId;
            UserApis.getLocations(this.selectedLocations.length, parentId, type)
                .then((response) => {
                this.locationList = response.data.payload;
            });
            if(parentId){
                this.getNodes(parentId);
            }
        },
        getNodes: function(parentId){
            UserApis.getNodesByLocation(parentId)
                .then((response) => {
                this.nodeList = response.data.payload;
            });
        },
        onLocationTabClick: function(index) {
            this.selectedNode = null;
            this.selectedNodes = [];
            if(index == -1) {
                this.selectedLocations = [];
            }
            if(index <= this.selectedLocations.length - 1) {
                this.selectedLocations.splice(index + 1);
            }
            this.fetchLocations();
        },
        updateSearch: function(){
            if(this.selectedNode != null){
                this.$emit("reload", this.selectedNode);
            }
        }
    },
};
</script>

<style lang="css" scoped>
.select_location {
    display: inline-block !important;
    margin-left: 5px;
}

.selected_location > span {
    color: #000;
    padding-top: 3px;
    padding-left: 5px;
    position: absolute;
    max-width: 100%;
    overflow-x: hidden;
    cursor: pointer;
}

.selected_location:after {
    border-left: 13px solid;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -13px;
}

.selected_location {
    width: 100px;
    height: 26px;
    display: inline-block;
    position: relative;
}

.selected_location_even {
    color: #afafaf;
    background: #afafaf;
}

.selected_location_even:after {
    color: #afafaf;
}

.selected_location_odd {
    color: #efefef;
    background: #efefef;
}
.selected_location_odd:after {
    color: #efefef;
}
.filter-btn {
    width: auto !important;
    height: 26px;
    padding: 0px 5px;
    background: #f3f3f3;
    color: #333;
    border: 1px solid #dedede;
}

/* .multiselect {
    height: 40px;
    display: flex;
    position: relative;
    bottom: 5px;
} */
.multiselect__tags {
    max-width: 400px;
    overflow: auto;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 40px 0px 4px !important;
    font-size: 12px !important;
}

.multiselect__tags-wrap{
    display: flex !important;
}
  
.multiselect__option--selected {
      background: #d7eafea3;
      color: #35495e;
      font-weight: 700;
}
</style>
