<template lang="html">
    <div class="loading-screen-wrapper" v-bind:class="{ hide: isHidden }">
        <div class="loader-bg"></div>
        <div class="loading-screen-icon">
            <div class="new-loader">
                <pulse-loader class="text-center" :size="'50px'" :color="'#203864'" />
            </div>
        </div>
        <div class="new-loader bg"></div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {events,eventBus} from '../../service/event.service'

export default {
  name: "loader",
  props:[],
  components:{PulseLoader},
  data () {
    return {
     isHidden:true
    }
  },
  created (){
    eventBus.on(events.showLoader, () => {
      this.isHidden = false;
    });
    eventBus.on(events.hideLoader, () => {
      this.isHidden = true;
    });
  }
}
</script>


<style lang="scss">
.loading-screen-wrapper {
    &.hide{
        display: none;
    }
  .loader-bg {
    z-index: 1000000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(59, 59, 60, 0.4);
  }
  .loading-screen-icon {
    z-index: 1000001;
    position: relative;
    height: 100%;
    width: 360px;
    margin: 0 auto;
    .loaderImg {
      width: calculateRem(67px);
      height: calculateRem(67px);
    }
    .new-loader{
      height: 200px;
      width: 360px;
      position: fixed;
      top: calc(100vh/2 - 150px);
      &.bg{
        background: transparent;
        background-position: -120px -200px;
        background-size: 600px 590px;
      }
    }
  }
}
</style>
