import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants'

function getLovByTypes(ids) {
    return APIBuilder.buildPostRequest(constants.LOV+"/bytypes", {types: ids })
}

function getLovDescription(lov, lovKey, lovCode){
    if(lov && lov[lovKey]){
        for(let entity of lov[lovKey]){
          if(entity.code == lovCode){
            return entity.description;
          }
        }
    }
    return lovCode
}

function getOptionsForSelect(lov, lovKey){
    let options = [];
    if(lov && lov[lovKey]){
        lov[lovKey].map(item=>options.push({value:item.code,label: item.description}));
    }
    return options;
}

export {
    getLovByTypes,
    getLovDescription,
    getOptionsForSelect
}