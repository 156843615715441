
<template>
    <div class="b-datetimepicker">
        <div role="group" class="form-group">
            <label v-if="label">{{label}}</label>
            <input class="form-control" ref="input" v-model="value" :disabled="disabled" v-bind:placeholder="placeholder" data-date-format="HH:mm" type="text" :title="content?content:placeholder"/>
            <i class="cil-calendar" @click="openPicker"></i>
        </div>
    </div>
</template>
<script>
import datetimepicker from 'vuejs-datetimepicker';
import moment from "moment";
import $ from "jquery";
export default {
    name: "BrdgeTimePicker",
    components: { datetimepicker },
    props: ['value', 'label', 'placeholder', 'disabled', 'error'],
    data () {
        return {
            content: null,
            modalVisible: false,
            format: "H:i:s",
            dateTimepicker:null,
            time: null
        }
    },
    watch: { 
        value: function() { // watch it
            console.log("TimePicker value : ", this.value);
            //this.time = this.value;
            this.value;
            this.updateContent();
        }
    },
    methods: {
        openPicker : function(ev){
            //this.time = null;
            ev.stopImmediatePropagation();
            if(this.dateTimepicker && !this.disabled){
                this.dateTimepicker.datetimepicker('show');
            }
        },
        update: function(date){
            this.content = date;
            this.$emit('input', date);
        },
        updateContent: function(){
            if(!this.value && this.content){
                this.content = null;
            }
            if(this.value && this.content != moment(this.value)){
                this.content = moment(this.value);
                $(this.$refs.input).data('DateTimePicker').date(this.content);
            }
        }
    },
    mounted: function(){
        this.dateTimepicker = $(this.$refs.input).datetimepicker()
            .on('dp.change', (event) => {
                this.update(event.date);
            });
        this.updateContent();
    }
}
</script>
<style scoped>
    .b-datetimepicker .form-group{
        position: relative;
    }
    .b-datetimepicker .form-group input{
        padding-right: 40px;
    }
    .b-datetimepicker .cil-calendar{
        position: absolute;
        right: 0;
        bottom: 0;
        height: 38px;
        padding: 10px;
        border-left: 1px solid #ddd;
        cursor: pointer;
    }
</style>