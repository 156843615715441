<template>
    <div>
        <VueHtml2pdf v-if="showPDF"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="filename"
            :title="filename"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1000px"
            ref="html2Pdf"
            :htmlToPdfOptions="{
                filename: `Name.pdf`,
                margin: [8, 8, 8, 8]
            }"
        >
            <section slot="pdf-content">
                <slot></slot>
            </section>
        </VueHtml2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
 
export default { 
    data() {
        return {
            showPDF: false,
            filename: "",
            htmlToPdfOptions:{
                filename: `Name.pdf`,
                margin: [8, 8, 8, 8]
            }
        }
    },
    components: {
        VueHtml2pdf
    },
    methods: { 
        generateReport () {
            if(this.$refs.html2Pdf)
                this.$refs.html2Pdf.generatePdf()
            else
                setTimeout(() => {
                    this.generateReport();
                }, 100);
        },
        openPreview(filename){
            this.filename = filename;
            this.htmlToPdfOptions.filename = filename;
            this.showPDF = true;
            this.generateReport();
        }
    },
}
</script>