<template>
    <div>
        <section ref="pdfContent">
            <slot></slot>
        </section>
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
 
export default { 
    data() {
        return {
            showPDF: false,
            filename: "",
            htmlToPdfOptions:{
                filename: `Name.pdf`,
                margin: [8, 8, 8, 8]
            }
        }
    },
    methods: { 
        async openPreview(filename){
            const element = this.$refs.pdfContent
            const opt = {
				margin: 0,
				filename: filename,
				image: {
					type: 'jpeg', 
					quality: 0.5
				},
				html2canvas: {
					scale: 2
				},
				jsPDF: {
					unit: 'in',
					format: 'a4',
					orientation: 'landscape'
				}
		    }
	        await html2pdf().set(opt).from(element).save()
        }
    },
}
</script>