import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.UOMS, data)
}

function fetchList() {
   
    return APIBuilder.buildGetRequest(constants.UOMS)
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.UOMS + "/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.UOMS + '/' + id, data)
}

function deleteItem(id) {
    return APIBuilder.buildDeleteRequest(constants.UOMS + '/' + id)
}


export {
    add,
    fetchList,
    details,
    update,
    deleteItem
}