import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY, data)
}

function fetchList() {
    return APIBuilder.buildGetRequest(constants.SKUCATGORY)
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.SKUCATGORY + "/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.SKUCATGORY + '/' + id, data)
}

function deleteSkumeta(id) {
    return APIBuilder.buildDeleteRequest(constants.SKUCATGORY + '/' + id)
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY+"/markActive", data)
}

function markHold(data) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY+"/markHold", data)
}

function markPhasedOut(data) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY+"/markPhasedOut", data)
}

function fetchCategoriesForSo() {
    return APIBuilder.buildGetRequest(constants.SKUCATGORY+"so")
}

function uploadBulk(fd) {
    return APIBuilder.buildPostRequest(constants.SKUCATGORY + '/bulk', fd)
}

export {
    add,
    fetchList,
    details,
    update,
    deleteSkumeta,
    markActive,
    markHold,
    markPhasedOut,
    markInactive,
    fetchCategoriesForSo,
    uploadBulk
}