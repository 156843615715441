<template lang="html">
  <div class="b-address">
    <div class="b-address-input-control">
        <i class="cil-location-pin"></i>
        <input ref="autocomplete" class="form-control" placeholder="Enter your address here" type="text" :disabled="disabled">
    </div>
    <div class="">
        <div class="b-address-wrapper">
            <div>
                <CInput :disabled="disabled" :label="`${label?'Line 1':''}`" :placeholder="`${placeholder?'Line 1':''}`" @input="update('line1')" v-model="content.line1" v-bind:class="errors.line1 ? 'error' : ''" />
                <span class="text-danger" v-if="errors.line1">{{errors.line1}}</span>
            </div>
            <div>
                <CInput :disabled="disabled" :label="`${label?'Line 2':''}`" :placeholder="`${placeholder?'Line 2':''}`" @input="update('line2')" v-model="content.line2" v-bind:class="errors.line2 ? 'error' : ''" />
                <span class="text-danger" v-if="errors.line2">{{errors.line2}}</span>
            </div>
        </div>
        <div class="b-address-wrapper">
            <div>
                <CInput :disabled="disabled" :label="`${label?'City':''}`" :placeholder="`${placeholder?'City':''}`" @input="update('city')" v-model="content.city" v-bind:class="errors.city ? 'error' : ''" />
                <span class="text-danger" v-if="errors.city">{{errors.city}}</span>
            </div>
            <div>
                <CInput :disabled="true" :label="`${label?'State':''}`" :placeholder="`${placeholder?'State':''}`" @input="update('state')" v-model="content.state" v-bind:class="errors.state ? 'error' : ''" />
                <span class="text-danger" v-if="errors.state">{{errors.state}}</span>
            </div>
            <!-- <div v-if="content.state != '' && content.country != ''">
                <CInput :disabled="true" :label="`${label?'Country':''}`" :placeholder="`${placeholder?'Country':''}`" @input="update('country')" v-model="content.country" v-bind:class="errors.country ? 'error' : ''" />
                <span class="text-danger" v-if="errors.country">{{errors.country}}</span>
            </div> -->
            <div>
                <label class="typo__label" v-if="label">Country</label>
                <multiselect
                    v-model="newCountry" 
                    :options="countries"
                    @input="updateCountry"
                    placeholder="Select one" 
                    label="label" 
                    track-by="value"
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false"
                    :disabled="disabled"
                ></multiselect>
                <span class="text-danger" v-if="errors.country">{{errors.country}}</span>
            </div>
            <div>
                <CInput :disabled="disabled" :label="`${label?'Pincode':''}`" :placeholder="`${placeholder?'Pincode':''}`" @input="update('pincode')" v-model="content.pincode" v-bind:class="errors.pincode ? 'error' : ''" />
                <span class="text-danger" v-if="errors.pincode">{{errors.pincode}}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import * as countriesAPI from '@common/utils/api/masters/countries'
import Multiselect from 'vue-multiselect'
export default {
  name: "BrdgeAddress",
  components: { Multiselect },
  props: {
    value: {
      default() {
        return {};
      },
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: true,
      type: Boolean,
    },
    label: {
      default: true,
      type: Boolean,
    },
    errors: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      content: {},
      countries: [],
      newCountry: {},
    };
  },
  mounted() {
    console.log(" Address Value ######: ", this.value);
    this.content = this.value;
    let autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */ (this.$refs.autocomplete),
      { types: ["geocode"] }
    );
    autocomplete.addListener("place_changed", () => {
      let place = autocomplete.getPlace();
      let ac = place.address_components;
      this.content = {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      };
      for (var i = 0; i < ac.length; i++) {
        switch (ac[i].types[0]) {
          case "street_number":
          case "route":
          case "neighborhood":
            this.content.line1 += ac[i].short_name + ",";
            break;
          case "sublocality_level_1":
          case "locality":
            this.content.line2 += ac[i].short_name + ",";
            break;
          case "administrative_area_level_2":
            this.content.city = ac[i].short_name;
            break;
          case "administrative_area_level_1":
            this.content.state = ac[i].long_name;
            break;
          case "country":
            this.content.country = ac[i].long_name.toUpperCase();
            console.log("this.content.country : ", this.content.country);
            this.newCountry = {value: this.content.country, label: this.content.country}
            break;
          case "postal_code":
            this.content.pincode = ac[i].long_name;
            break;
        }
        if(ac.includes('country')){
          console.log("this.content.country : ", this.content.country);
        }
        console.log("ac: ", ac);
      }
      this.update();
    });
    Promise.allSettled([countriesAPI.getCountries()]).then(values=>{
        if(values[0].status == 'fulfilled'){
            let countryList = values[0].value.data.payload;
            this.countries = [];
            countryList.forEach(element => {
                this.countries.push({value:element.name, label: element.name});
            });
            let list = this.countries;
            let filteredCountry = list.filter(item => {
                return item.value == this.content.country;
            });
            this.newCountry = filteredCountry.length ? filteredCountry[0] : {};
            if(this.newCountry){
              delete  this.errors.country_id;
              this.content.country = this.newCountry.value;
            } else {
              this.content.country = null;
            }
        }
    });
  },
  watch: {
    value: function () {
      // watch it
      console.log("Address Component Value : ", this.value);
      this.content = this.value;
      if (!this.content) {
        this.content = {};
      }
      let list = this.countries;
      let filteredCountry = list.filter(item => {
          return item.value == this.content.country;
      });
      this.newCountry = filteredCountry.length ? filteredCountry[0] : {};
      if(this.newCountry){
        delete  this.errors.country_id;
        this.content.country = this.newCountry.value;
      } else {
        this.content.country = null;
      }
      this.$forceUpdate();
    },
    errors: function () {
      if (!this.errors) {
        this.errors = {};
      }
    },
  },
  methods: {
    updateCountry: function(){
      if(this.newCountry){
        delete  this.errors.country_id;
        this.content.country = this.newCountry.value;
      } else {
        this.content.country = null;
      }
    },
    update: function (key = null) {
      if (key) {
        this.errors[key] = "";
      } else {
        this.errors.line1 = null;
        this.errors.line2 = null;
        this.errors.city = null;
        this.errors.state = null;
        this.errors.country = null;
        this.errors.pincode = null;
      }
      this.$emit("input", this.content);
      this.$forceUpdate();
    },
    resetAddress: function () {
      this.content = {};
      this.errors = {};
      this.$emit("input", this.content);
    },
    updateAddress:function(content){
      this.content = {};
      console.log("Address Component Value : ", content)
      this.content = content;
      this.update();
      let list = this.countries;
      let filteredCountry = list.filter(item => {
          return item.value == this.content.country;
      });
      this.newCountry = filteredCountry.length ? filteredCountry[0] : {};
      if(this.newCountry){
        delete  this.errors.country_id;
        this.content.country = this.newCountry.value;
      } else {
        this.content.country = null;
      }
      this.$emit("input", this.content);
      this.$forceUpdate();
    }
  },
};
</script>

<style lang="css" scoped>
.b-address-wrapper {
  display: inline-table;
  table-layout: fixed;
  width: 50%;
}
.b-address-wrapper > div {
  display: table-cell;
  padding: 2px 5px;
}
.b-address-input-control{
    padding: 2px 5px;
    position: relative;
}
.b-address-input-control .form-control{
    padding-left: 40px;
}
.b-address-input-control i{
    position: absolute;
    top: 2px;
    left: 7px;
    font-size: 25px;
    border-right: 1px solid #ced4da;
    height: 38px;
    padding: 6px 2px;
    color: #359aff;
}
.b-address-wrapper > div.input-group {
  display: inline-block;
}

.typo__label { 
  padding: 0px;
  margin: 0.5rem;
  position: relative;
  top: 0px;
}
</style>