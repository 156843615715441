import Vue from '@common/utils/sessionManager'
import axios from 'axios'

const vue = new Vue();

function buildGetRequest(url) {
    return axios.get(url)
}

function buildGetRequestWithResponseType(url, responseType) {
    return axios({
        method: 'get',
        url: url,
        responseType: responseType
    })
}

function buildPostRequest(url, data) {
    return axios.post(url, data)
}

function buildFilePostRequest(url, data) {
    var headers = {};
    headers['Content-Type'] = 'multipart/form-data'
    return axios.post(url,
        data, { headers: headers })
}

function buildPutRequest(url, data) {
    return axios.put(
        url,
        data)
}

function buildDeleteRequest(url) {
    return axios.delete(url)
}

export { buildGetRequest, buildGetRequestWithResponseType, buildPutRequest, buildPostRequest, buildFilePostRequest, buildDeleteRequest }