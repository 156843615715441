<template>
    <div class="home-page-background">
        <CContainer class="d-flex align-items-center min-vh-100">
            <CRow class="justify-content-center w-100">
                <CCol md="5">
                    <CCardGroup class="backdrop-box">
                        <CCard class="p-4">
                            <CCardBody>
                                <div class="text-center border-btm">
                                    <img src="@streams/assets/BRDgeLogoMay21.png" class="logo"/>
                                </div>
                                <CForm>
                                    <div class="server-error">
                                        <h4>Application is down.</h4>
                                        <p class="text-muted">Please contact your system administrator.</p>
                                    </div>
                                    <CRow>
                                        <CCol col="12">
                                            <CButton color="info login-btn" class="px-4" @click="reload">Retry</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
export default {
    name: "ErrorApp",
    methods:{
        reload:function(){
            window.location.reload()
        }
    }
}
</script>
<style scoped>
  .logo {
    width: 160px;
    margin: 0px 0px 10px 0px;
  }
  .server-error{
      padding-top: 10px;
  }

  .login-text{
    position: absolute;
    bottom: 0px;
  }

  .backdrop-box{
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    box-sizing: border-box;
    -webkit-box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    -moz-box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  }

  .p-5{
    padding-top: 5%;
  }

  .p-0{
    padding: 0 !important;
  }

  .border-btm{
    border-bottom: 1px solid #efefef;
  }

  .login-btn{
    width: 100%;
    /* color: #203864;
    background: #FFC000; */
    background: #203864;
  }

  .input-group-text{
    background: #FFC000;
  }

  .icon-dark-color{
    color: #203864;
  }

  .home-page-background{
    /* background-image: url("~@streams/assets/bg-rightTop.png"), url("~@streams/assets/bg-leftBottom.png"); */
    background-image: url("~@common/assets/bg-rightTopFaded.svg"), url("~@common/assets/bg-leftBottomFaded.svg");
    background-repeat: no-repeat;
    background-position: right top, left bottom;
  }

  body{
    background: #f9f9f9;
  }
</style>