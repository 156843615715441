import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.LOCATION_TYPES, data)
}

function fetchList() {
   
    return APIBuilder.buildGetRequest(constants.LOCATION_TYPES)
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.LOCATION_TYPES + "/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.LOCATION_TYPES + '/' + id, data)
}

function deleteItem(id) {
    return APIBuilder.buildDeleteRequest(constants.LOCATION_TYPES + '/' + id)
}

function getAllLocationTypes() {
   
    return APIBuilder.buildGetRequest(constants.LOCATION_TYPES + '/all')
}

function getAllLocationTypesWithLocations() {
   
    return APIBuilder.buildGetRequest(constants.LOCATION_TYPES + '/locations')
}


export {
    add,
    fetchList,
    details,
    update,
    deleteItem,
    getAllLocationTypes,
    getAllLocationTypesWithLocations
}