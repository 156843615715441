import { eventBus, events } from "./event.service";

let showLoader = ()=>{
  eventBus.dispatch(events.showLoader);
}

let hideLoader = ()=>{
  eventBus.dispatch(events.hideLoader);
}

export {showLoader, hideLoader}