<template>
    <div v-if="percentages">
        <div class="fs-6 fw-semibold text-primary pb-2" v-if="label">
            {{label}}
        </div>
        <div class="progres-wrapper" :style="`max-height:${height?height+'px':'auto'}`">
            <div v-for="(pb, index) in percentages" :key="index">
                <div class="mb-2">
                    <div>
                    <span>{{ pb.label }}</span>
                    <span class="float-right">{{ pb.Quantity }}({{ pb.Percentage }}%)</span>
                    </div>
                    <CProgress :color="progressColor[index]" style="height: 10px" class="mt-1">
                        <CProgressBar :value="pb.Percentage"/>
                    </CProgress>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"HorizontalPercentage",
    props:["label", "percentages", "height"],
    data() {
        return {
            progressColor: ['primary', 'success', 'danger', 'warning', 'info', 'primary', 'success', 'danger', 'warning', 'info', 'primary', 'success', 'danger', 'warning', 'info']
        }
    }
}
</script>
<style scoped>
.progres-wrapper{
    overflow: auto;
    padding-right: 5px;
}
</style>