import * as action from "@common/components/grid/action.datatable";
let getTable = function (reference, gridname) {
  return [
    {
        title: 'SKU Name',
        data: 'skuDetails.name',
        "render": function (data, type, row) {
          return row.skuDetails ? row.skuDetails.name : "-";
        }
      },
    {
      title: 'SKU Code',
      data: 'alternate_sku_code',
      "render": function (data, type, row) {
        return row.skuDetails.code != undefined ? row.skuDetails.code : "-";
      }
    },
    
    {
      title: 'Stock Norm',
      data: 'manual_stock_norm',
      "render": function (data, type, row) {
        return row.manual_stock_norm != undefined ? row.manual_stock_norm : "-";
      }
    },
   
  
    {
      title: 'Buyer',
      data: 'buyerDetails.first_name',
      "render": function (data, type, row) {
        return row.buyerDetails ? (row.buyerDetails.first_name ? (row.buyerDetails.first_name + " " + row.buyerDetails.last_name) : row.buyerDetails.email) : "-";
      }
    },
    {
      title: 'Nature',
      data: 'skuDetails.nature',
      "render": function (data, type, row) {
        return row.skuDetails ? row.skuDetails.nature : "-";
      }
    },
    {
        title: 'Status',
        data: 'statusDetails.code',
        "render": function (data, type, row) {
          return row.statusDetails ? row.statusDetails.code : "-";
        }
      },
    {
        title: 'Unit Price',
        data: 'price',
        "render": function (data, type, row) {
            console.log("Price Data:",row);
          return row.skuDetails ? row.skuDetails.price : "-";
        }
      },
    action.getActionCell({
        title: '',
        data: 'actions',
        floatingFilter: false,
        orderable: false,
        class:"text-center",
        width: "80px",
        buttons: [{
          size: 'sm',
          class: 'btn btn-warning btn-sm',
          style: 'margin-right: 5px;',
          title: 'Edit',
          icon: {
            class: "text-white fa fa-pencil"
          },
          method: reference.editItem.bind(reference)
        },
      ]
      }, reference)
    ]
  }


export { getTable }