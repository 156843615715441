import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

// add data to nodesku table through file

function addThroughFile (data) {
  console.log("data is ",data)
 let fd = new FormData();
    if (data.file) {
        fd.append('files', data.file);
        console.log('files in util', data.file)
   }
     return APIBuilder.buildPostRequest(constants.NODESKUTHROUGHFILE, fd)
}

function add(data) {
  console.log("api data is",data)
    return APIBuilder.buildPostRequest(constants.NODESKU, data)
}

// retrive data by availabiltiy zone to show dashbord tabel
function fetchList(availability_zone) {

     if(availability_zone!=null) {
      return APIBuilder.buildGetRequest(constants.NODESKUBYZONE+"?availability_zone=" +availability_zone)
     }
     else {
        return APIBuilder.buildGetRequest(constants.NODESKUBYZONE)
     }
   
    
}

// retrive data by paretid to show table conetent after click on bar chart

function NodefetchList(parentid) {
  console.log("utils parentid is",parentid)
    return APIBuilder.buildGetRequest(constants.NODESKUBYPARENT+"?parentid=" +parentid)
}

//get sku by node

function fetchSKUsByNodeId(node) {
  //console.log("network call nodeid",node)
    if(node!=null) {
      return APIBuilder.buildGetRequest(constants.NODESKUS  +"?id="+node)  
    }
    else {
      return APIBuilder.buildGetRequest(constants.NODESKUS )  
    }


    //console.log(node)
  
}

function fetchNodesBySkuId(sku) {
  //console.log("network call nodeid",node)
    if(sku!=null) {
      return APIBuilder.buildGetRequest(constants.NODESKUS  +"?sku="+sku)  
    }
    else {
      return APIBuilder.buildGetRequest(constants.NODESKUS )  
    }


    //console.log(node)
  
}
function getNodesBySkuId(sku_id) {
    if(sku_id!=null) {
      return APIBuilder.buildGetRequest(constants.NODESKUSID  +"?sku_id="+sku_id)  
    }
    else {
      return APIBuilder.buildGetRequest(constants.NODESKUSID )  
    }


    //console.log(node)
  
}

function fetchSKUByNodeList(id) {
  console.log("network call nodeid",id)
    if(id!=null) {
      return APIBuilder.buildGetRequest(constants.NODESKUS  +"?id="+id)  
    }
    else {
        return APIBuilder.buildGetRequest(constants.NODESKUS )  
    }


    //console.log(node)
    
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.NODESKU + "/" + id)
}

function searchNodeSKUName(name) {
   console.log("name in constants is ",name)
    return APIBuilder.buildGetRequest(constants.SERACHNODESKUNAME +"/"+ name)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.NODESKU + '/' + id, data)
}

function deleteNodesku(id) {
    return APIBuilder.buildDeleteRequest(constants.NODESKU + '/' + id)
}

function uploadBulk(fd) {
  return APIBuilder.buildPostRequest(constants.NODESKU + '/bulk', fd)
}

function markInactive(data) {
  return APIBuilder.buildPostRequest(constants.NODESKU+"/markInactive", data)
}

function markActive(data) {
  return APIBuilder.buildPostRequest(constants.NODESKU+"/markActive", data)
}

function markHold(data) {
  return APIBuilder.buildPostRequest(constants.NODESKU+"/markHold", data)
}

function markPhasedOut(data) {
  return APIBuilder.buildPostRequest(constants.NODESKU+"/markPhasedOut", data)
}

//fetch Supplier on the basis sku
function fetchSupplierBySkuId(sku_id) {
  //console.log("network call nodeid",node)
    if(sku_id!=null) {
      return APIBuilder.buildGetRequest(constants.NODEBYSKUID  +"?sku="+sku_id)  
    }
  


    //console.log(node)
  
}

export {
    addThroughFile,
    add,
    fetchList,
    details,
    update,
    deleteNodesku,
    fetchSKUByNodeList,
    fetchSKUsByNodeId,
    fetchNodesBySkuId,
    NodefetchList,
    searchNodeSKUName,
    uploadBulk,
    markActive,
    markHold,
    markPhasedOut,
    markInactive,
    fetchSupplierBySkuId,
    getNodesBySkuId
}