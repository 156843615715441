<template lang="html">
  <div>
    <div v-for="(order, index) in orderDetails" :key="index">
      <div class="node" :class="className">
        <div class="node-row">
          <div
            class="node-action"
            @click="
              order.isOpen = !order.isOpen;
              parentToggle(order);
            "
          >
            <i class="fa fa-minus-circle" v-if="order.isOpen"></i>
            <i class="fa fa-plus-circle" v-else></i>
          </div>
          <span class="editBtn" @click="onSideBar(order)">
            {{ order.order_id ? order.order_id : order.job_code ? order.job_code : order.order_details.order_id }}
          </span>
          <!-- <a class="editBtn" @click="onSideBar(order)">
                        <i class="text-warning fa fa-list"></i>
                    </a> -->
        </div>
        <b-iwo-tree
          class="child-node"
          v-on="$listeners"
          :orderDetails="order.orderDetails"
          v-if="
            order.isOpen && order.orderDetails && order.orderDetails.length > 0
          "
        ></b-iwo-tree>
        <p
          v-if="
            order.isOpen && order.orderDetails && order.orderDetails.length == 0
          "
          class="text-danger"
        >
          No Further Orders Available.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as apis from "@connect/utils/api/iwo.js";
import * as loaderService from "@common/service/loader.service";
import * as toasterService from "@common/service/toaster.service";

export default {
  name: "iwoTree",
  components: {},
  props: {
    orderDetails: [],
    ids: {},
    disableControl: false,
    openSelected: false,
    isCheckboxVisible: true,
    className: "",
    relatedOrderDetails: [],
  },
  data() {
    return {
      activeName: "",
    };
  },
  watch: {
    orderDetails: function (newVal, oldVal) {
      console.log("orderDetails Updates:", this.orderDetails);
    },
  },
  mounted() {},
  methods: {
    parentToggle: function (order) {
      console.log("Emmited Display:", order);
      if(order.isOpen){
        this.toggle(order);
      }
      this.$forceUpdate();
    },
    toggle: function (order) {
      let order_product_details_id = null;
      let order_details_id = null;
      if(order.order_details_id){
        order_product_details_id = order.id
      }else{
        order_details_id = order.id
      }
      loaderService.showLoader();
      apis
        .fetchList(order_details_id,order_product_details_id)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            let orders = response.data.payload;
            console.log("related IWO:", orders);
            // let filteredOrders = orders.filter((item) => {
            //     return item.sales_order_id == sale_order_id.id;
            // });
            // let ordersArr = [];
            // orders.forEach((element) => {
            //     let temp = {
            //     checkable: true,
            //     id: element.sku,
            //     orderDetails: [],
            //     parent: order.id,
            //     tags: "iwo",
            //     text: element.skuDetails.name,
            //     };
            //     ordersArr.push(temp);
            // });
            //node.nodes = nodesArr;
            order.orderDetails = orders;
            this.$forceUpdate();
            console.log("IWOs : ", order.orderDetails);
            //console.log("Filtered SKUs : ", filteredSkus);
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload
            ? err.response.data.payload
            : err.message;
          toasterService.error(err.message);
        })
        .finally(() => {
          loaderService.hideLoader();
        });

      // this.$forceUpdate();
    },
    closeAll() {
      for (let order of this.orderDetails) {
        this.close(order);
      }
    },
    open: function (order) {
      order.isOpen = true;
      this.$forceUpdate();
    },
    close: function (order) {
      order.isOpen = false;
      this.$forceUpdate();
    },
    // updateids(details) {
    //   this.ids = details[0];
    //   this.$emit("checkChanged", details);
    // },
    onSideBar(order) {
      console.log("order at Side Bar: ", order);
      this.$emit("sideBar", order);
    },
  },
};
</script>

<style>
.nodeUiClass {
  /* box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -webkit-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -moz-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72); */
  margin: 5px 0px;
}
.node {
  position: relative;
}
.node .child-node {
  margin-left: 25px;
  position: relative;
}
.node .child-node::after {
  content: " ";
  height: calc(100% - 5px);
  position: absolute;
  left: -20px;
  width: 0px;
  border: 1px solid;
  border-style: dotted;
  top: -5px;
}
.node .node-action {
  cursor: pointer;
  display: inline-block;
  padding-right: 5px;
}
.node .node-title {
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
  line-height: 28px;
  margin-left: -5px;
}
.child-node.hide {
  display: none;
}
.child-node .node::after {
  content: " ";
  height: 0;
  position: absolute;
  left: -18px;
  width: 10px;
  border: 1px solid;
  border-style: dotted;
  top: 12px;
}
.node .form-check {
  display: inline-block;
  vertical-align: middle;
  padding-right: 0px;
}
.node .form-check .node-title {
  padding-left: 0;
}
.node .hide-checkbox .form-check {
  display: none !important;
}

.node-row {
  display: inline-block;
  padding: 0px 5px;
  margin: 2px 0px;
  border-radius: 5px;
}
.node-row:active {
  background: aliceblue;
}
.border-location {
  /* background: #1f3863;
    color: #fff; */
  /* border: 2px solid #333; */
  background: #fff;
  color: #203864;
}
.border-node {
  /* background: #4da4f0;
    color: #000; */
  /* border: 2px solid #d65806; */
  background: #fff;
  color: #203864;
}
.border-category {
  /* border: 2px solid #3187f2; */
  /* background: #1f3863;
    color: #fff; */
  /* border: 1px solid #203864; */
  background: #fff;
  color: #203864;
}
.border-sku {
  /* border: 2px solid #d0d0d0; */
  background: #e0dcdc;
  color: #203864;
}
.addBtn {
  margin-left: 1rem;
  padding: 5px;
  cursor: pointer;
}
.editBtn {
  /* margin-left: .5rem; */
  padding: 5px;
  cursor: pointer;
}
</style>
