
class SessionManager {
  install (Vue, options) {
    Vue.setUser = this.setUser
    Vue.getUser = this.getUser
    Vue.setCenter = this.setCenter
    Vue.getCenter = this.getCenter
    Vue.setToken = this.setToken
    Vue.getToken = this.getToken
    Vue.isUserLoggedIn = this.isUserLoggedIn
    Vue.getUserOrganization = this.getUserOrganization
    Vue.isUser = this.isUser
    Vue.setCompany = this.setCompany
    Vue.getCompany = this.getCompany
    Vue.logout = this.logout
    Vue.setCountry = this.setCountry
    Vue.getCountry = this.getCountry
    Vue.setCity = this.setCity
    Vue.getCity = this.getCity
    Vue.setCountryCode = this.setCountryCode
    Vue.getCountryCode = this.getCountryCode
  }

  setUser (user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser () {
    //return JSON.parse(localStorage.getItem('user'))
    return window.keycloakImplObj.userDetails;
  }

  setCenter (center) {
    localStorage.setItem('center', JSON.stringify(center))
  }

  getCenter () {
    return JSON.parse(localStorage.getItem('center'))
  }

  setToken (token) {
    localStorage.setItem('token', token)
  }

  getToken () {
    return localStorage.getItem('token')
  }

  isUserLoggedIn () {
    return localStorage.getItem('token') !== null
  }

  isUser (role) {
    return JSON.parse(localStorage.getItem('user')).role === role
  }

  logout () {
    //localStorage.clear()
    window.keycloakImplObj.logout();
  }

  getCountry () {
    return localStorage.getItem('country')
  }

  setCountry (country) {
    localStorage.setItem('country', country)
  }

  getCity () {
    return localStorage.getItem('city')
  }

  setCity (city) {
    localStorage.setItem('city', city)
  }

  getCountryCode () {
    return localStorage.getItem('countryCode')
  }

  setCountryCode (country) {
    localStorage.setItem('countryCode', country)
  }
}

export default SessionManager
