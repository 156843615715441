<template lang="html">
    <transition>
      <div :id="modalName" class="modals modal_move">
      <div class="modals-content">
        <slot></slot>
      </div>
    </div>
</transition>
</template>

<script>
export default {
  props: {
    modalName: {
      type: String,
      required: true
    },
  },
data () {
  return {
    modalVisible: false,
  }
},
methods: {
  openModal: function() {
      console.log("Open model Method Called", this.modalName);
      let modal = document.getElementById(this.modalName);
      modal.classList.add("open");
    },

    closeModal: function() {
     
      let modal = document.getElementById(this.modalName);
      modal.removeAttribute("style");
      modal.classList.remove("open");
      // this.$forceUpdate();
      console.log("Here Close Method Called",this.modalName);
      
    }

    
}
}
</script>

<style lang="scss" scoped> 
/* Updated CSS with open selector */
									
.modals.open {
  transform: translateX(0px);
						
}

.modals {
  /* Update CSS with transition and transform rules */
  transition: transform 0.5s linear;
  transform: translateX(100%);
  position: fixed;
  z-index: 9999  ;
  right: 0;
  top: 0;
  width: 780px;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255);
  /* box-shadow: 5px 10px #888888; */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.modals-content {
  width: 100%;
  /* background-color:#f5f6fb */
}

.closes {
  color: #323232;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closes:hover,
.closes:focus {
  color: #424242;
  text-decoration: none;
  cursor: pointer;
}



.sidemodal td{
    padding:2px!important;
}




.bottom-btn{
    position: absolute;
    bottom: 0;
    width: 100%;
}
 .scroll{
    max-height: 40vh;
    overflow-y: scroll;
}

.no-tb-m{
    margin-bottom:0;
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.multiselect .multiselect__tags{
    min-height: 0px!important;
    padding: 2px 40px 0 8px!important;
}
.sidemodal td .form-group {
    margin-bottom:0;
}
.sidemodal td .form-group select{
    padding: 0rem 0.5rem;
    font-size: 0.80rem;
    font-weight:500;
}

/* .sidemodal:hover td{
    background-color: #fff!important;
  } */

  .dataTable thead .sidemodal{
     background-color: #fff!important;
     padding:5px;
  }
  .dataTable thead .sidemodal td{
    color:#3c4b64;
     font-size:14px;
    font-weight: 500;
  }
  .dataTable tbody .sidemodal{
     background-color: #fff!important;
  }
  /* .sidemodal td{
     border: 5px solid  #fff!important;
  } */


</style>