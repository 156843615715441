<template lang="html">
  <SidePanel ref="sidePanel" :modal-name="'myModal10'">
    <header class="modal-header">
      <p class="modal-title"><b>{{ nodeName }}</b></p>
      <!-- <p class="modal-title"><b>{{ skus[0].nodeDetails.name }}({{ skus[0].nodeDetails.code  }})</b></p> -->
      <button
        type="button"
        @click="closeModel()"
        aria-label="Close"
        class="close pull-right"
      >
        ×
      </button>
    </header>
    <div class="model-body">
    <CCard>
      <CCardHeader>
        <strong>NodeSKU </strong>
      <div class="card-header-actions">
        
        <!-- <CButton v-if="statusBtns.active" class="btn btn-success btn-sm"  @click="markActive" title="Mark Active">
            Mark Active
          </CButton> -->
          &nbsp;
          <CButton v-if="statusBtns.inactive" class="btn btn-warning btn-sm"  @click="markInactive"  title="Mark Inactive">
            Mark Inactive
          </CButton>
          &nbsp;
          <CButton v-if="statusBtns.hold" class="btn btn-warning btn-sm"  @click="markHold"  title="Mark Hold">
            Mark Hold
          </CButton>
          &nbsp;
          <CButton v-if="statusBtns.phasedOut" class="btn btn-danger btn-sm"  @click="markPhasedOut" title="Mark Phased Out">
            Mark Phased Out
          </CButton>
        </div>
      </CCardHeader>
        <CCardBody>
          <grid2 ref="grid" :rowselection="true" @selection-changed="onSelectionChanged" :responsive="true" :serverUrl="serverUrl"  :columnDefs="columnDefs"></grid2>
      </CCardBody>
      </CCard>
      </div>
      <CRow>
        <CCol class="text-right">
          <CButton @click="closeModel" color="danger" class="mr-2"
            >Cancel</CButton
          >
          <!-- <CButton color="success">Add</CButton> -->
        </CCol>
      </CRow>
      <EditNodeSkuMappingTree ref="editModal" @reload="reload" />
    <!-- </div> -->
  </SidePanel>
</template>

<script>
import * as nodeSkuApi from "@common/utils/api/masters/nodeskus.js";
import SidePanel from "@common/components/sidePanel/sidePanelTree.vue";
import * as Constants from '@common/utils/constants'
import grid2 from "@common/components/grid/grid.datatable";
import grid from "@common/components/grid/grid";
import * as tableConfig from "./skuListSideBar.table";
import EditNodeSkuMappingTree from "@common/components/tree/componentNew/EditNodeSkuMappingTree";
import * as apis from "@common/utils/api/masters/nodeskus";
import * as loaderService from "@common/service/loader.service";
import * as toasterService from '@common/service/toaster.service'
import actionCell from "@common/components/grid/action";
export default {
  name: "SKUListSidePanel",
  components: { SidePanel,grid2,grid,EditNodeSkuMappingTree,actionCell },
  props: [],
  data() {
    return {
      skus: [],
      nodeName:'',
      serverUrl: undefined,
      columnDefs: tableConfig.getTable(this, 'grid'),
      nodeID:'',
      queryStatus: '',
      selectedRows: [],
      selectedIds: [],
      statusBtns: {
        active: false,
        inactive: false,
        phasedOut: false,
        hold: false,
      },
    };
  },
  methods: {
    loadDetails: function (node) {
      console.log("Node Details:",node.text);
      this.nodeName = node.text
      nodeSkuApi
        .fetchSKUsByNodeId(node.id)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            let skus = response.data.payload;
            let filteredSkus = skus.filter((item) => {
              return item.node == node.id;
            });
           
            this.skus = filteredSkus;
            console.log("Filtered SKUs## : ", this.skus)
            this.$refs.sidePanel.openModal();
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload
            ? err.response.data.payload
            : err.message;
          toasterService.error(err.message);
        });
    },
    show: function (node) {
      this.nodeID = node.id;
      this.nodeName = node.text;
      this.$refs.sidePanel.openModal();
      //this.loadDetails(node);
      this.queryStatus = '?node=' + this.nodeID;
      this.updateGridUrl();
      
    },
    updateGridUrl: function(){
        this.serverUrl = Constants.SIDEGRID + this.queryStatus;
        this.columnDefs = tableConfig.getTable(this, 'grid');
      
      },
      onSelectionChanged: function (selectedRows) {
      this.selectedRows = selectedRows;
      this.updateStatusBtns();
    },
    updateStatusBtns: function () {
      this.selectedIds = [];
      if (this.selectedRows.length == 0) {
        this.statusBtns = {
          active: false,
          inactive: false,
          phasedOut: false,
          hold: false,
        };
        return;
      }
      let statusBtns = {
        active: true,
        inactive: true,
        phasedOut: true,
        hold: true,
      };
      for (let row of this.selectedRows) {
        if(row.statusDetails?.code){
          switch (row.statusDetails.code.toLowerCase()) {
            case "active":
              statusBtns.active = false;
              break;
            case "hold":
              statusBtns.hold = false;
              break;
            case "inactive":
              statusBtns.inactive = false;
              break;
            case "phased out":
              statusBtns.phasedOut = false;
              break;
          }
        }
        this.selectedIds.push(row.id);
      }
      this.statusBtns = statusBtns;
    },
    markActive: function () {
      loaderService.showLoader();
      apis
        .markActive(this.selectedIds)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            let fetchList = false;
            if(response.data.payload){
              for(let message of response.data.payload){
                if(message.indexOf("cannot")>1){
                  toasterService.error(message);
                }
                else{
                  fetchList = true;
                  toasterService.success(message);
                }
              }
            }
            else{
              toasterService.success("Status(s) marked active successfully.");
            }
            if(fetchList){
              this.fetchList();
            }
            else{
              loaderService.hideLoader();
            }
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          toasterService.error(err.message);
          loaderService.hideLoader();
        });
    },
    markInactive: function () {
      loaderService.showLoader();
      apis
        .markInactive(this.selectedIds)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            toasterService.success("Status(s) marked inactive successfully.");
            this.fetchList();
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          toasterService.error(err.message);
          loaderService.hideLoader();
        });
    },
    markHold: function () {
      loaderService.showLoader();
      apis
        .markHold(this.selectedIds)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            toasterService.success("Status(s) marked hold successfully.");
            this.fetchList();
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          toasterService.error(err.message);
          loaderService.hideLoader();
        });
    },
    markPhasedOut: function () {
      loaderService.showLoader();
      apis
        .markPhasedOut(this.selectedIds)
        .then((response) => {
          if (response.data.status.status_code === 0) {
            toasterService.success("Status(s) marked phased out successfully.");
            this.fetchList();
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          toasterService.error(err.message);
          loaderService.hideLoader();
        });
    },
    fetchList: function () {
      this.$refs.grid.redraw();
    },
    closeModel: function () {
      this.$refs.sidePanel.closeModal();
    },
    editItem: function (item) {
      this.$refs.editModal.show(item);
    },
    reload(){
      this.$refs.grid.redraw()
    },
  },
};
</script>

<style lang="css" scoped>
/* .node-title {
    cursor: pointer;
    display: inline-block;
    padding-left: 5px;
    line-height: 28px;
    margin-left: -5px;
} */
.model-body {
  padding: 10px;
}
.form-group.error {
  margin-bottom: 0;
}
.text-danger {
  display: inline-block;
  margin-bottom: 1rem;
  margin-top: 5px;
}
.editNodeDiv {
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  height: 100vh;
  padding: 2rem;
  z-index: 1050;
  border-left: 5px solid #dedede;
}
.modal-close-btn {
  padding: 10px 0px 10px 10px;
  position: relative;
  top: 30vh;
  right: 12%;
  background: #fff;
  border-radius: 10%;
  border: 2px solid #dedede;
  border-right: 0;
}

/* ********* Slide Animation ************ */
.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in 0.5s ease-in forwards;
  transition: opacity 0.5s;
}

.slide-leave-active {
  animation: slide-out 0.5s ease-out forwards;
  opacity: 0;
  transition: opacity 0.5s;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

#backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1050;
}
</style>
