import axios from 'axios'
import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as Constants from '../constants'


function getProductLists() {
    return APIBuilder.buildGetRequest(Constants.PRODUCT_LIST);
}

export{
    getProductLists
}