<template lang="html">
   <SidePanel ref="sidePanel" :modal-name="'myModal12'">
      <header class="modal-header">
        <p class="modal-title"><b>Edit NodeSKU Mapping</b></p>
        <button
          type="button"
          @click="closeModel()"
          aria-label="Close"
          class="close pull-right"
        >
          ×
        </button>
      </header>
      <div class="model-body">
    <CRow>
      <CCol>
        <CInput
          label="Alternate SKU Code"
          placeholder="Enter alternate sku code"
          v-model="skucode"
          disabled="true"
          v-bind:class="errors.skucode ? 'error' : ''" />
        <span class="text-danger" v-if="errors.skucode">{{errors.skucode}}</span>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CInput
          label="Alternate Node Code"
          placeholder="Enter alternate node code"
          v-model="nodecode"
          disabled="true"
          v-bind:class="errors.nodecode ? 'error' : ''" />
        <span class="text-danger" v-if="errors.nodecode">{{errors.nodecode}}</span>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CSelect
          label="SKU"
          custom
          @update:value="changesku"
          :options="options"
          v-model="skumasterid"
          placeholder="Please select"
          disabled="true"
          v-bind:class="errors.sku ? 'error' : ''" />
          <span class="text-danger" v-if="errors.sku">{{errors.sku}}</span>
      </CCol>
      <CCol>
        <CSelect 
          label="Node"
          custom
          @update:value="changenode"
          :options="options1"
          v-model="nodeid"
          placeholder="Please select"
          disabled="true"
          v-bind:class="errors.node ? 'error' : ''" />
          <span class="text-danger" v-if="errors.node">{{errors.node}}</span>
      </CCol>
      <CCol>
        <CSelect 
          label="Buyer"
          custom
          @update:value="changeBuyer"
          :options="buyersList"
          v-model="selectedBuyer"
          placeholder="Select Buyer"
          v-bind:class="errors.buyer ? 'error' : ''" />
          <span class="text-danger" v-if="errors.buyer">{{errors.buyer}}</span>
      </CCol>
    </CRow>

    <CRow v-if="Object.keys(selectedSku).length">
      <hr>
      <CCol>
        <CInput
          label="Price"
          placeholder="Enter Price"
          v-model.number="selectedSku.price"
          v-bind:class="errors.skuprice ? 'error' : ''" />
        <span class="text-danger" v-if="errors.skuprice">{{errors.skuprice}}</span>
      </CCol>
      <CCol>
        <CSelect
          label="GST Percentage"
          :options="gstTypes"
          v-model="selectedSku.gst_percentage"
          placeholder="Select GST Percentage"
          @change.native="getGstType"
          v-bind:class="errors.gst_percentage ? 'error' : ''" />
        <span class="text-danger" v-if="errors.gst_percentage">{{errors.gst_percentage}}</span>
      </CCol>
      <CCol>
        <CSelect
          v-model="selectedSku.nature"
          label="Sku Nature"
          :options="skuNatures"
          placeholder="Select Sku Nature"
          @change.native="noSkuNature"
          v-bind:class="errors.nature ? 'error' : ''" />
        <span class="text-danger" v-if="errors.nature">{{errors.nature}}</span>
      </CCol>
      <CCol>
        <CSelect
          label="UOM"
          custom
          v-model="selectedSku.uom"
          @change.native="onUOMChange"
          :options="uoms"
          placeholder="UOM"
          v-bind:class="errors.selectedUOM ? 'error' : ''" />
        <span class="text-danger" v-if="errors.selectedUOM">{{errors.selectedUOM}}</span>
      </CCol>
    </CRow>
    <CRow v-if="Object.keys(selectedSku).length">
      <CCol>
        <CSelect
          label="Manufacturer UOM"
          v-model="selectedSku.uom_manufacturer"
          custom
          @change.native="onUOMManufacturerChange"
          :options="uoms"
          placeholder="Manufacturer UOM"
          v-bind:class="errors.selectedUOMManufacturer ? 'error' : ''" />
        <span class="text-danger" v-if="errors.selectedUOMManufacturer">{{errors.selectedUOMManufacturer}}</span>
      </CCol>
      <CCol>
        <CSelect
          label="Supplier UOM"
          v-model="selectedSku.uom_supplier"
          custom
          @change.native="onUOMSupplierChange"
          :options="uoms"
          placeholder="Supplier UOM"
          v-bind:class="errors.selectedUOMSupplier ? 'error' : ''" />
        <span class="text-danger" v-if="errors.selectedUOMSupplier">{{errors.selectedUOMSupplier}}</span>
      </CCol>
      <CCol>
        <CSelect
          label="Consumer UOM"
          v-model="selectedSku.uom_consumer"
          custom
          @change.native="onUOMConsumerChange"
          :options="uoms"
          placeholder="Consumer UOM"
          v-bind:class="errors.selectedUOMConsumer ? 'error' : ''" />
          <span class="text-danger" v-if="errors.selectedUOMConsumer">{{errors.selectedUOMConsumer}}</span>
      </CCol>
    </CRow>
    <CRow v-if="Object.keys(selectedSku).length">
      <CCol>
        <CInput
          label="Manufacturer MOQ"
          v-model="selectedSku.moq_manufacturer"
          :options="uoms"
          v-bind:class="errors.moqManufacturer ? 'error' : ''" />
        <span class="text-danger" v-if="errors.moqManufacturer">{{errors.moqManufacturer}}</span>
      </CCol>
      <CCol>
        <CInput
          label="Supplier MOQ"
          v-model="selectedSku.moq_supplier"
          :options="uoms"
          v-bind:class="errors.moqSupplier ? 'error' : ''" />
        <span class="text-danger" v-if="errors.moqSupplier">{{errors.moqSupplier}}</span>
      </CCol>
      <CCol>
        <CInput
          label="Consumer MOQ"
          v-model="selectedSku.moq_consumer"
          :options="uoms"
          v-bind:class="errors.moqConsumer ? 'error' : ''" />
        <span class="text-danger" v-if="errors.moqConsumer">{{errors.moqConsumer}}</span>
      </CCol>
      <hr>
    </CRow>

    <CRow>
      <CCol>
        <CInput
        label="StockNorm"
        placeholder="enter the stock norms"
        v-model="stocknorm"
        v-bind:class="errors.stocknorm ? 'error' : ''" />
        <span class="text-danger" v-if="errors.stocknorm">{{errors.stocknorm}}</span>
      </CCol>
      <CCol>
        <CInput
        label="Replenishment Cycle"
        placeholder="Replenishment Cycle"
        v-model="replenishmentCycle"
        v-bind:class="errors.replenishmentCycle ? 'error' : ''" />
        <span class="text-danger" v-if="errors.replenishmentCycle">{{errors.replenishmentCycle}}</span>
      </CCol>
      <CCol>
        <CInput
        label="Stocknorm Threshold"
        placeholder="Stocknorm Threshold"
        v-model="stocknormThreshold"
        v-bind:class="errors.stocknormThreshold ? 'error' : ''" />
        <span class="text-danger" v-if="errors.stocknormThreshold">{{errors.stocknormThreshold}}</span>
      </CCol>
    </CRow>
<!--     
    <template #footer>
      <CButton @click="modalVisible = false" color="danger">Discard</CButton>
      <CButton @click="edit" color="success" >Update</CButton>
    </template> -->
    <CRow>
                <CCol class="text-right">
                  <CButton @click="closeModel" color="danger" class="mr-2">Discard</CButton>
                  <CButton @click="edit" color="success" >Update</CButton>
                </CCol>
              </CRow>
    </div>
</SidePanel>
</template>
<script>
import * as apis from "@common/utils/api/masters/nodeskus";
import * as skumaster_api from "@common/utils/api/masters/sku";
import * as node_api from "@common/utils/api/masters/nodes";
import * as UserApis from "@common/utils/api/masters/users";
import * as loaderService from "@common/service/loader.service";
import * as toasterService from "@common/service/toaster.service";
import * as lovAPI from '@common/utils/api/masters/lov';
import * as skuTypeApis from "@common/utils/api/masters/skuType";
import * as uomApis from "@common/utils/api/masters/uom";
import SidePanel from "@common/components/sidePanel/sidePanelTree.vue";
export default {
    name: "EditNodeSkuMappingTree",
  components: { SidePanel },
  data() {
    return {
      error: null,
      errors : {},
      id: null,
      skucode: null,
      nodecode: null,
      stocknorm: null,
      skumasterid: null,
      nodeid: null,
      skus: [],
      options: [],
      options1: [],
      modalVisible: false,
      buyersList: [],
      selectedBuyer: null,
      replenishmentCycle: null,
      stocknormThreshold: null,
      selectedSku: {},
      types: [],
      uoms: [],
      lovs: {},
      skuNatures: [],
      gstTypes: [],
    };
  },
  mounted: function(){
  
  },
  methods: {
    getLovs: function() {
      loaderService.showLoader();
      lovAPI
        .getLovByTypes(["SkuNature", "GST_TYPE"])
        .then((response) => {
          if (response.data.status.status_code === 0) {
            this.lovs = response.data.payload;
            this.skuNatures = lovAPI.getOptionsForSelect(this.lovs, 'SkuNature');
            this.gstTypes = lovAPI.getOptionsForSelect(this.lovs, 'GST_TYPE');
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          toasterService.error(err.message);
          loaderService.hideLoader();
        });      
    },
    loadSkuDetails: function () {
      loaderService.showLoader();
      Promise.allSettled([
        skuTypeApis.fetchList(),
        uomApis.fetchList(),
      ])
      .then((values) => {
        if (values[0].status == "fulfilled") {
          let data = values[0].value.data.payload;
          this.types = [];
          data.map((item) =>
            this.types.push({ value: item.id, label: item.name })
          );
        } else {
          this.error = values[0].reason;
        }
        if (values[1].status == "fulfilled") {
          let data = values[1].value.data.payload;
          this.uoms = [];
          data.map((item) =>
            this.uoms.push({ value: item.id, label: item.name })
          );
        } else {
          this.error = values[1].reason;
        }
      })
      .catch((err) => {
        this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
      })
      .finally(() => {
        loaderService.hideLoader();
      });
    },
    changesku: function (value) {
      this.skumasterid = value;
    },

    changenode: function (value) {
      let nodeid = null;
      this.nodeid = value;
    },

    show: function (item) {
      this.loadDetails();
      this.getLovs();
      this.loadSkuDetails();
      this.id = item.id;
      this.skucode = item.alternate_sku_code;
      this.nodecode = item.alternate_node_code;
      this.stocknorm = item.manual_stock_norm?.toString();
      this.skumasterid = item.sku;
      this.nodeid = item.node;
      this.selectedBuyer = item.buyer;
      this.replenishmentCycle = item.replenishment_cycle?.toString();
      this.stocknormThreshold = item.running_stocknorm_instances_threshold?.toString();

      let filteredSkus = this.skus.filter(item => {
        return item.id == this.skumasterid;
      });
      let selectedSkuData = filteredSkus.length ? filteredSkus[0] : {};

      this.selectedSku.nature = item.sku_nature ? item.sku_nature : selectedSkuData.nature;
      this.selectedSku.price = item.price ? item.price : selectedSkuData.price;
      this.selectedSku.gst_percentage = item.gst_percentage ? item.gst_percentage : selectedSkuData.gst_percentage;
      this.selectedSku.uom = item.sku_uom ? item.sku_uom : selectedSkuData.uom;
      this.selectedSku.uom_manufacturer = item.uom_manufacturer ? item.uom_manufacturer : selectedSkuData.uom_manufacturer;
      this.selectedSku.uom_supplier = item.uom_supplier ? item.uom_supplier : selectedSkuData.uom_supplier;
      this.selectedSku.uom_consumer = item.uom_consumer ? item.uom_consumer : selectedSkuData.uom_consumer;
      this.selectedSku.moq_manufacturer = item.moq_manufacturer ? item.moq_manufacturer : selectedSkuData.moq_manufacturer;
      this.selectedSku.moq_supplier = item.moq_supplier ? item.moq_supplier : selectedSkuData.moq_supplier;
      this.selectedSku.moq_consumer = item.moq_consumer ? item.moq_consumer : selectedSkuData.moq_consumer;
      
      this.loadDetails();
      this.$refs.sidePanel.openModal();
    },
    closeModel: function () {
      this.$refs.sidePanel.closeModal();
    },
    loadDetails: function () {
      if (!this.options || this.options.length == 0) {
        loaderService.showLoader();
        Promise.allSettled([
          skumaster_api.fetchList(),
          node_api.fetchList(),
          UserApis.fetchList(),
        ])
          .then((values) => {
            if (values[0].status == "fulfilled") {
              let data = values[0].value.data.payload;
              this.skus = data;
              data.map((item) =>
                this.options.push({
                  value: item.id,
                  label: item.name + " (" + item.code + ")",
                })
              );
            } else {
              this.error = values[0].reason;
            }
            if (values[1].status == "fulfilled") {
              let data = values[1].value.data.payload;
              data.map((item) =>
                this.options1.push({ value: item.id, label: item.name })
              );
            } else {
              this.error = values[1].reason;
            }
            if (values[2].status == "fulfilled") {
              let list = values[2].value.data.payload;
              this.buyersList = [];
              for (let index = 0; index < list.length; ++index) {
                let label = "";
                if (list[index].first_name) {
                  label =
                    list[index].first_name +
                    (list[index].last_name ? " " + list[index].last_name : "");
                } else {
                  label = list[index].email;
                }
                this.buyersList.push({ value: list[index].id, label });
              }
            } else {
              this.error = values[1].reason;
            }
          })
          .catch((err) => {
            this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
          })
          .finally(() => {
            loaderService.hideLoader();
          });
      }
    },
    edit: function () {
      this.errors = {};
      this.error = null;
      let isError = false;
      // if(!this.skucode){
      //   this.errors.skucode = "Alternate SKU Code is required.";
      //   isError = true;
      // }
      if(!this.skumasterid){
        this.errors.sku = "SKU is required.";
        isError = true;
      }
      if(!this.nodeid){
        this.errors.node = "Node is required.";
        isError = true;
      }
      if(!this.selectedBuyer){
        this.errors.buyer = "Buyer is required.";
        isError = true;
      }
      if(!this.stocknorm){
        this.errors.stocknorm = "Manual Stocknorm is required.";
        isError = true;
      }
      if(!this.replenishmentCycle){
        this.errors.replenishmentCycle = "Replenishment Cycle is required.";
        isError = true;
      }
      if(!this.stocknormThreshold){
        this.errors.stocknormThreshold = "Stocknorm Threshold is required.";
        isError = true;
      }
      if(isError){
        return;
      }
      loaderService.showLoader();
      let alternate_sku_code = this.skucode.trim();
      let alternate_node_code = this.nodecode;
      let manual_stock_norm = this.stocknorm.trim();
      let replenishment_cycle = this.replenishmentCycle.trim();
      let running_stocknorm_instances_threshold =
        this.stocknormThreshold.trim();
      let sku = this.skumasterid;
      let node = this.nodeid;
      let buyer = this.selectedBuyer;
      let sku_nature = this.selectedSku.nature;
      let price = this.selectedSku.price;
      let gst_percentage = this.selectedSku.gst_percentage;
      let sku_uom = this.selectedSku.uom;
      let uom_manufacturer = this.selectedSku.uom_manufacturer;
      let uom_supplier = this.selectedSku.uom_supplier;
      let uom_consumer = this.selectedSku.uom_consumer;
      let moq_manufacturer = this.selectedSku.moq_manufacturer;
      let moq_supplier = this.selectedSku.moq_supplier;
      let moq_consumer = this.selectedSku.moq_consumer;
      apis
        .update(this.id, {
          alternate_sku_code,
          alternate_node_code,
          manual_stock_norm,
          replenishment_cycle,
          running_stocknorm_instances_threshold,
          sku,
          node,
          buyer,
          sku_nature,
          price,
          gst_percentage,
          sku_uom,
          uom_manufacturer,
          uom_supplier,
          uom_consumer,
          moq_manufacturer,
          moq_supplier,
          moq_consumer
        })
        .then((response) => {
          if (response.data.status.status_code === 0) {
            toasterService.success("Node SKU updated successfully.");
            this.$emit("reload");
            (this.skucode = null),
            (this.nodecode = null),
              (this.stocknorm = null),
              (this.skumasterid = null),
              (this.nodeid = null),
              (this.modalVisible = false);
            this.replenishmentCycle = null;
            this.stocknormThreshold = null;
          } else {
            this.error = response.data.status.message;
            // this.skucode = null,
            //  this.stocknorm = null
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
        })
        .finally(() => {
          loaderService.hideLoader();
        });
    },
    changeBuyer: function (value) {
      this.selectedBuyer = value;
    },
    noSkuNature: function(evt) {
      this.selectedSku.nature = evt.target.value;
    },
    getGstType: function(evt) {
      this.selectedSku.gst_percentage = evt.target.value;
    },
    onUOMChange: function (evt) {
      this.selectedSku.uom = evt.target.value;
    },
    onUOMManufacturerChange: function (evt) {
      this.selectedSku.uom_manufacturer = evt.target.value;
    },
    onUOMSupplierChange: function (evt) {
      this.selectedSku.uom_supplier = evt.target.value;
    },
    onUOMConsumerChange: function (evt) {
      this.selectedSku.uom_consumer = evt.target.value;
    },
  },
};
</script>

<style lang="css" scoped>
.model-body {
  padding: 10px;
}
.form-group.error {
  margin-bottom: 0;
}
.text-danger {
  display: inline-block;
  margin-bottom: 1rem;
  margin-top: 5px;
}
</style>
