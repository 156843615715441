<template lang="html">
  <span class="b-textrea">
    <!-- :class="{'disabled': cuurrentpostatus == 'for_approv' }" -->
    <CButton size="sm" class="m-2" :class="{'disabled': status_assigned_to != userid? true:false }" :color="color" @click="modalVisible = true" v-if="label">{{label}}</CButton>
    
    <CModal v-if="modalVisible"
      :title="label?label:(placeholder?placeholder:'Enter values')"
      :show.sync="modalVisible"
      :backdrop="true" :close-on-backdrop="false" :close-on-esc="false"
      size="lg">
      <CTextarea v-model="content" rows="7" @input="update" :disabled="disabled" :placeholder="label?label:(placeholder?placeholder:'Enter values')"/>
      <template #footer>
        <CSelect size="md" v-if="label == 'Review'" class="m-2"  @change.native="updateSelected($event)"  :options="reporting" placeholder="Select Reviewer"/>
        <CSelect size="md" v-if="label == 'Submit' || label == 'Mark As Revised'" class="m-2" @change.native="updateReportingOfficer($event)" :options="reporting" placeholder="Select Reporting Officer"/>
        <CButton size="sm" class="m-2" :class="(label == 'Submit' && assignedUser == '')?'disabled':''" @click="updateValue(status)" :color="color" v-if="label">{{label}}</CButton>
        <CButton size="sm" class="m-2" color="danger" @click="modalVisible = false">Close</CButton>
      </template>
    </CModal>
  </span>
</template>

<script>
import * as loaderService from '@common/service/loader.service'
export default {
    name: 'BrdgeTextArea',
    props: ['value', 'label', 'placeholder', 'disabled','color','status','reporting','status_assigned_to','userid'],
    data () {
        return {
          content: null,
          modalVisible: false,
          potypes:[],
          assignedUser:'',
          errors:{
            po_type:''
          }
        }
    },
    watch: { 
        value: function() { // watch it
          this.content = this.value
        }
    },
    methods: {
      update: function(){
        this.$emit('input', this.content);
      },
      updateSelected: function(evt){
        this.assignedUser = evt.target.value;
        this.$emit('assigned-user', this.assignedUser);
      },
      updateReportingOfficer: function(evt){
        this.assignedUser = evt.target.value;
        this.$emit('assigned-reporting-user', this.assignedUser);
      },

      updateValue: function(status){
        this.modalVisible = false;
        this.$emit('status-updated', status);
      },
    },
  mounted: function(){
    this.content = this.value;
  }
}
</script>

<style lang="css" scoped>
.b-textrea .form-group {
  position: relative;
}
.b-textrea .form-group .form-control{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 35px;
}
.b-textrea .form-group i{
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: 0px;
  cursor: pointer;
  height: 38px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 35px;
  padding-left: 5px;
  border-left: 1px solid #ced4da;
}
</style>
