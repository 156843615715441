import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.USER, data)
}

function fetchList() {
    return APIBuilder.buildGetRequest(constants.USERS)
}

function getUserRole(id) {
    return APIBuilder.buildPostRequest(`${constants.USER}/roles?id=${id}`, {})
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.USER + "/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.USER + '/' + id, data)
}

function deleteUser(id) {
    return APIBuilder.buildDeleteRequest(constants.USER + '/' + id)
}

function getDashboardData(parentId, level, type, nodeId) {
    let query = '?';
    if(parentId) {
            query += 'parent_id=' + parentId + '&'
    }
    query += 'level=' + (level ? level : 0) 

    if(type) {
        query += '&' + 'parent_type=' + type
    }

    if(nodeId != null) {
        query += '&' + 'node_id=' + nodeId
    }

    return APIBuilder.buildGetRequest(constants.USER + '/me/dashboard' + query);
}

function getStocknorm(parentId, date, type, metaId) {
    let query = '?';
    if(parentId) {
            query += 'parent_id=' + parentId + '&'
    }

    if(metaId) {
        query += 'meta_id=' + metaId + '&'
    }

    if(type) {
        query += 'type=' + type
    }
    // query += 'level=' + level + '&' + 'date=' + date

    return APIBuilder.buildGetRequest(constants.USER + '/me/stocknorm' + query);
}

function getStocknormConsumer(parentId, date, type, metaId) {
    let query = '?';
    if(parentId) {
            query += 'parent_id=' + parentId + '&'
    }

    if(metaId) {
        query += 'meta_id=' + metaId + '&'
    }

    if(type) {
        query += 'type=' + type
    }
    // query += 'level=' + level + '&' + 'date=' + date

    return APIBuilder.buildGetRequest(constants.USER + '/me/stocknorm/consumer' + query);
}

function getStocknormSuppliers(consumer, sku, data) {
    return APIBuilder.buildPostRequest(constants.USER + '/me/stocknorm/details/' + consumer + '/' + sku, data);
}

function getStocknormSummary(localtion, category, parent_location,selectedNode, selectedSKU, stocked, data) {
    let query = '?'
    if(localtion){
        query += 'parent_id=' + localtion + '&';
    }
    if(category){
        query += 'meta_id=' + category + '&';
    }
    if(parent_location){
        query += 'location_parent_id=' + parent_location + '&';
    }
    if(selectedNode){
        query += 'node=' + selectedNode + '&';
      }
      if(selectedSKU){
        query += 'sku=' + selectedSKU + '&';
      }
      if(stocked == true){
        query += 'stocked=' + stocked
      }else{
        query += 'stocked=' + stocked;
      }
    return APIBuilder.buildPostRequest(constants.USER + '/me/stocknorm/consumer/summary' + query, data);
}

function getStocknormForExcel(url,queryparam, data) {
    console.log('Select url',url);
    console.log('Select querParam',queryparam);
    console.log('Select data',data);
    let newUrl = url+'/excel?'+queryparam;
    return APIBuilder.buildPostRequest(newUrl, data);
}

function getSupplierStocknorm(parentId, date, type) {
    let query = '?';
    if(parentId) {
            query += 'parent_id=' + parentId + '&'
    }

    if(type) {
        query += 'type=' + type
    }
    // query += 'level=' + level + '&' + 'date=' + date

    return APIBuilder.buildGetRequest(constants.USER + '/me/stocknorm' + query);
}

function getLocations(level, parentId) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/locations?level=' + level + '&parent_id=' + parentId);
}

function getNodesBySkuChangeStatus(availabilityZone) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/nodesbychange?availability_zone=' + availabilityZone);
}

function search(query) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/search/' + query);
}

function getSupplierTrend(id, type) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/suppliertrend/' + id + '?interval=' + type);
};

function getSkuTrend(id, type) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/skutrend/' + id + '?interval=' + type);
};

function getConsumerTrend(id, type) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/consumertrend/' + id + '?interval=' + type);
};

function getNodesBySKUsPerZone(zone) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/nodesbyskusperzone/' + zone);
}

function getSKUsByNodesPerZone(zone) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/skusbynodesperzone/' + zone);
}

function getNodesWithLastChangeDateForSKU(id, zone) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/dayssincelastchange/sku/' + id + '/' + zone);
}

function getSKUsWithLastChangeDateForNode(id, zone) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/dayssincelastchange/node/' + id + '/' + zone);
}

function getCategories(level, parentId) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/skumetas?level=' + level + '&parent_id=' + parentId);
}

function getSKUsByCategory(parentId){
    return APIBuilder.buildGetRequest(constants.USER + '/me/skus/' + parentId);
}

function getTableFields(name) {
    return APIBuilder.buildGetRequest(constants.USER_PREFERENCE + '/viewpref/' + name);
}

function putTableFields(name, data) {
    return APIBuilder.buildPutRequest(constants.USER_PREFERENCE + '/viewpref/' + name, data);
}

function getTableFieldsPermissions(name) {
    return APIBuilder.buildGetRequest(constants.USER_PREFERENCE + '/reportpermittedfields/' + name);
}

function getSupplierList() {
    return APIBuilder.buildGetRequest(constants.USER + '/me/suppliers');
}

function getDispatch(id) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/dispatch/' + id);
}

function getNodes() {
    return APIBuilder.buildGetRequest(constants.USER + '/me/nodes');
}

function getNodesByLocation(locationId) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/nodes/' + locationId);
}

function getNodeSkus(id) {
    return APIBuilder.buildGetRequest(constants.USER + '/stocknorms/consumer/' + id);
}

function getSalesSkuTrend(id, type) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/skutrend/consumption/' + id + '?interval=' + type);
};

function getSalesConsumerTrend(id, type) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/consumertrend/consumption/' + id + '?interval=' + type);
};

function extendSession(){
    return APIBuilder.buildGetRequest(constants.BEAT);
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.USER+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.USER+"/markActive", data)
}

function getReportingOfficer(data) {
    return APIBuilder.buildPostRequest(`${constants.USER}/rpofficer`, {id:data})
}

function getConsumerNodes(supplier_node_id) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/consumernodes/' + supplier_node_id);
}

function getUserNodeMaps(node_id) {
    return APIBuilder.buildGetRequest(constants.USER + '/me/usernodemaps/' + node_id);
}

function getACRgraph(data) {
    console.log('Select startDate',data.startDate);
    console.log('Select endDate',data.endDate);
    return APIBuilder.buildPostRequest(constants.USER + '/me/getACRgraph', data);
}

function getActualStockForExcel(url) {
    let newUrl = url+'/excel';
    return APIBuilder.buildGetRequest(newUrl);
}

export {
    add,
    fetchList,
    details,
    update,
    getReportingOfficer,		
    deleteUser,
    getDashboardData,
    getStocknorm,
    getStocknormConsumer,
    getStocknormSuppliers,
    getStocknormSummary,
    getStocknormForExcel,
    getSupplierStocknorm,
    getLocations,
    getNodesBySkuChangeStatus,
    search,
    getSupplierTrend,
    getSkuTrend,
    getConsumerTrend,
    getNodesBySKUsPerZone,
    getSKUsByNodesPerZone,
    getNodesWithLastChangeDateForSKU,
    getSKUsWithLastChangeDateForNode,
    getCategories,
    getTableFields,
    putTableFields,
    getTableFieldsPermissions,
    getSupplierList,
    getDispatch,
    getNodes,
    getNodeSkus,
    getSalesSkuTrend,
    getSalesConsumerTrend,
    getUserRole,
    extendSession,
    markActive,
    markInactive,
    getNodesByLocation,
    getSKUsByCategory,
    getConsumerNodes,
    getUserNodeMaps,
    getACRgraph,
    getActualStockForExcel
}