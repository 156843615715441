var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.nodes),function(node,index){return _c('div',{key:index},[_c('div',{staticClass:"node",class:_vm.className},[_c('div',{staticClass:"node-row",class:[
          _vm.activeName == node.id ? 'active' : '',
          node.tags == 'location' ? ' border-location' : '',
          node.tags == 'node' ? ' border-node' : '',
          node.tags == 'category' ? ' border-category' : '',
          node.tags == 'sku' ? ' border-sku' : '' ]},[(node.nodes || node.tags == 'node')?_c('div',{staticClass:"node-action",on:{"click":function($event){return _vm.parentToggle(node)}}},[(node.isOpen)?_c('i',{staticClass:"fa fa-minus-circle"}):_c('i',{staticClass:"fa fa-plus-circle"})]):_vm._e(),(_vm.isCheckboxVisible)?_c('CInputCheckbox',{attrs:{"disabled":_vm.disableControl,"checked":_vm.ids[node.id]},on:{"change":function($event){return _vm.onCheckBoxChange(node)}}}):_vm._e(),_c('span',{staticClass:"node-title",on:{"click":function($event){return _vm.parentToggle(node)}}},[_vm._v(_vm._s(node.text)),_c('sub',{class:node.status_name == 'Active'
                ? 'pl-2 text-success'
                : 'pl-2 text-danger'},[_vm._v("("+_vm._s(node.status_name)+")")])]),(node.tags != 'sku')?_c('a',{staticClass:"addBtn",on:{"click":function($event){return _vm.onAdd(node)}}},[_c('i',{staticClass:"text-warning fa fa-plus"})]):_vm._e(),_c('a',{staticClass:"editBtn",on:{"click":function($event){return _vm.onEdit(node)}}},[_c('i',{staticClass:"text-warning fa fa-pencil"})]),(node.tags == 'node')?_c('a',{staticClass:"editBtn",on:{"click":function($event){return _vm.onSideBar(node)}}},[_c('i',{staticClass:"text-warning fa fa-list"})]):_vm._e()],1),(node.isOpen && node.nodes && node.nodes.length > 0)?_c('b-tree',_vm._g({staticClass:"child-node",attrs:{"isCheckboxVisible":false,"disableControl":true,"ids":_vm.ids,"nodes":node.nodes},on:{"checkChanged":_vm.updateids,"sideBar":_vm.onSideBar,"OnclickChild":_vm.toggle,"editNode":_vm.onEdit,"addNode":_vm.onAdd}},_vm.$listeners)):_vm._e()],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }