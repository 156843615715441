<template lang="html">
  <div class="b-textrea">
    <div class="form-group">
      <label v-if="label">{{label}}</label>
      <div class="form-control" v-bind:class="disabled?'disabled':''" :title="content?content:placeholder">{{content?content:placeholder}}</div>
      <i class="cil-keyboard" @click="modalVisible = true" v-bind:class="disabled?'disabled':''"/>
    </div>
    <CModal v-if="modalVisible"
      :title="label?label:(placeholder?placeholder:'Enter values')"
      :show.sync="modalVisible"
      :backdrop="true" :close-on-backdrop="false" :close-on-esc="false"
      size="lg">
      <CTextarea v-model="content" rows="7" @input="update" :disabled="disabled" :placeholder="label?label:(placeholder?placeholder:'Enter values')"/>
      <template #footer>
        <CButton @click="modalVisible = false">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>

export default {
    name: 'BrdgeTextArea',
    props: ['value', 'label', 'placeholder', 'disabled'],
    data () {
        return {
          content: null,
          modalVisible: false
        }
    },
    watch: { 
        value: function() { // watch it
          this.content = this.value
        }
    },
    methods: {
      update: function(){
        this.$emit('input', this.content);
      }
    },
  mounted: function(){
    this.content = this.value;
  }
}
</script>

<style lang="css" scoped>
.b-textrea .form-group {
  position: relative;
}
.b-textrea .form-group .form-control{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 35px;
}
.b-textrea .form-group i{
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: 0px;
  cursor: pointer;
  height: 38px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 35px;
  padding-left: 5px;
  border-left: 1px solid #ced4da;
}
</style>
