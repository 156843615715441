const currencyFilter = function (value) {
    if(!value){
        return "-";
    }
    if (typeof value === "string") {
        value = parseFloat(value);
    }
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR'
    });
    return formatter.format(value);
};

const resgisterFilter = function(Vue){
    Vue.filter('currency', currencyFilter);
}

export{
    resgisterFilter
}