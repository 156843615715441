import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '../constants'


function fetchList(order_details_id,order_product_details_id) {
    let url = `${constants.INTERNAL_WORK_ORDER_PROD_DETAILS}?`;

    if (order_details_id) {
        url += `orderDetailsId=${order_details_id}`;
    }

    if (order_product_details_id) {
        // Add '&' if `order_details_id` was also added
        url += (order_details_id ? '&' : '') + `orderProductDetailsId=${order_product_details_id}`;
    }

    return APIBuilder.buildGetRequest(url);
    // return APIBuilder.buildGetRequest(constants.INTERNAL_WORK_ORDER_PROD_DETAILS+ '/' + orderId)
}
function fetchorderProdcutById(Id) {
    return APIBuilder.buildGetRequest(constants.ORDER_PROD_DETAILS_BY_ID + '/' + Id)
}
function fetchrequestOrderByType(type) {
    //return APIBuilder.buildGetRequest(`${Constants.SALES}/getProductCode/${type}`);
    return APIBuilder.buildGetRequest(`${constants.INTERNAL_WORK_ORDER_PROD_DETAILS}/requiredorders/${type}`)
}
export {
     fetchList,
    fetchorderProdcutById,
    fetchrequestOrderByType
}