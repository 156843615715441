import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.NODE, data)
}

function fetchList() {
    
    return APIBuilder.buildGetRequest(constants.NODES)
}

function fetchHierarchy() {
    return APIBuilder.buildGetRequest(constants.NODES + '/hierarchy')
}
//First Stage For Tree View
function fetchTreeHierarchy() {
    return APIBuilder.buildGetRequest(constants.NODES + '/treehierarchy')
}
function fetchHierarchyOnClick(id) {
    return APIBuilder.buildGetRequest(constants.NODES + '/hierarchy/'+id)
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.NODE + "/" + id)
}

function nodeDetails(code) {
    return APIBuilder.buildGetRequest(constants.NODE + "/code/" + code)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.NODE + '/' + id, data)
}

function deleteNode(id) {
    return APIBuilder.buildDeleteRequest(constants.NODE + '/' + id)
}

function uploadBulk(fd) {
    return APIBuilder.buildPostRequest(constants.NODE + '/bulk', fd)
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.NODE+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.NODE+"/markActive", data)
}

function markHold(data) {
    return APIBuilder.buildPostRequest(constants.NODE+"/markHold", data)
}

function markPhasedOut(data) {
    return APIBuilder.buildPostRequest(constants.NODE+"/markPhasedOut", data)
}

function getCommunicationDetails(id, type = 'all'){
    return APIBuilder.buildGetRequest(constants.MASTER_API+"/node/details/"+id + '?type=' + type);
}

//For Map Integration
function getNodeWithAddress(){
    return APIBuilder.buildGetRequest(constants.MASTER_API+"/nodewithaddress");
}

function fetchListbyType(type) {
    return APIBuilder.buildGetRequest(constants.NODES + '?type=' + type);
}


export {
    add,
    fetchList,
    fetchHierarchy,
    details,
    nodeDetails,
    update,
    deleteNode,
    uploadBulk,
    markActive,
    markHold,
    markPhasedOut,
    markInactive,
    getCommunicationDetails,
    fetchListbyType,
    getNodeWithAddress,
    fetchHierarchyOnClick,
    fetchTreeHierarchy
}