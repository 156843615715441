<template lang="html">
    <div class="b-address">
        <span><b>Select Category : </b></span>
        <CButton color="secondary" class="filter-btn" @click="onCategoryTabClick(-1)">All</CButton> 
        <span v-for="(item, index) in selectedCategories" :key="item.value" > / 
            <CButton color="secondary" class="filter-btn" @click="onCategoryTabClick(index)">{{item.label}}</CButton>
        </span>
        <div class="select_location" v-if="categoriesList.length > 0"> / 
            <CSelect
                label=""
                placeholder="Select Category"
                v-model="serverCategory"
                :options="categoriesList"
                @change.native="onCategorySelect" 
                style="display: inline-block;"/>
        </div>
        <div class="select_location">
            <multiselect
                v-model="selectedSKUs" 
                :options="skuList"
                @input="onSKUSelect"
                label="name" 
                track-by="id"
                :searchable="true" 
                :close-on-select="false" 
                :show-labels="false"
                :multiple="true"
            ></multiselect>
        </div>
        <CButton color="primary" class="ml-2 btn-sm" @click="updateSearch()">
            <i class="fa fa-search"></i>
        </CButton>
    </div>
</template>

<script>
import * as UserApis from '@common/utils/api/masters/users';
import Multiselect from 'vue-multiselect'
export default {
    name: "BrdgeAddress",
    components: { Multiselect },
    props: {},
    data() {
        return {
            categoriesList: [],
            selectedCategories: [],
            serverCategory: '',
            skuList: [],
            selectedSKU: null,
            selectedSKUs: [],
            selectedLocations: []
        };
    },
    mounted() {
        this.getUserProfile();
        this.fetchCategories();
    },
    watch: {},
    methods: {
        //User Drilldown For Locations
        getUserProfile: function() {
            var locations = this.getDrilldown();
            locations.forEach((element, index) => {
                let tempArr = {
                    label: element.name,
                    value: element.id,
                    type: 0
                }
                this.selectedLocations.push(tempArr);
            });
            this.selectedLocCount = this.selectedLocations.length;
            //this.fetchLocations();
        },
        getDrilldown: function() {
            console.log("window.keycloakImplObj.userDetails.drilldown : ", window.keycloakImplObj.userDetails.drilldown);
            return window.keycloakImplObj.userDetails.drilldown;
        },
        //Category & SKU Selection 
        onCategoryTabClick: function(index) {
            if(index == -1) {
                this.selectedCategories = [];
            }
            if(index <= this.selectedCategories.length - 1) {
                this.selectedCategories.splice(index + 1);
            }
            this.fetchCategories();
        },
        onCategorySelect: function(evt) {
            this.selectedSKU = null;
            this.selectedSKUs = [];
            if('any' == evt.target.value) {
                return;
            }
            for(let index = 0; index < this.categoriesList.length; ++index) {
                if(this.categoriesList[index].value == evt.target.value) {
                    this.selectedCategories.push(this.categoriesList[index]);
                }
            }
            this.levelDownCount = this.actualAllCount;
            this.serverCategory = this.selectedCategories[this.selectedCategories.length - 1].value;
            this.fetchCategories();
            if(this.serverCategory != null){
                this.$emit("CategorySelect", this.serverCategory);
            }
        },
        fetchCategories: function() {
            let parentId = null;
            let type = 0;
            if(this.selectedCategories.length > 0) {
                parentId = this.selectedCategories[this.selectedCategories.length - 1].value;
                type = this.selectedCategories[this.selectedCategories.length - 1].type;
            }
            this.serverCategory = parentId;
            UserApis.getCategories(this.selectedCategories.length, parentId, type)
            .then((response) => {
                let catData = response.data.payload;
                let tempCat = [];
                catData.forEach(element => {
                let tempArr = {
                    value: element.id,
                    label: element.name
                }
                tempCat.push(tempArr);
                });
                this.categoriesList = tempCat;
            });
            if(parentId){
                this.getSKUs(parentId);
            }
        },
        getSKUs: function(parentId){
            UserApis.getSKUsByCategory(parentId)
            .then((response) => {
                this.skuList = response.data.payload;
            });
        },
        onSKUSelect: function() {
            this.selectedSKU = null;
            if(this.selectedSKUs.length == 0) {
                return;
            }
            //this.selectedSKU = value.target.value;
            this.serverCategory = this.selectedCategories[this.selectedCategories.length - 1].value;
            let list = [];
            this.selectedSKUs.forEach(element => {
                list.push(element.id); 
            });
            this.selectedSKU = list.join(',');
            console.log("SKU : ", this.selectedSKUs);
            console.log("serverCategory : ", this.serverCategory);
            console.log("SKU : ", this.selectedSKU);

        },
        updateSearch: function(){
            if(this.selectedSKU != null){
                this.$emit("reload", this.selectedSKU);
            }
        }
    },
};
</script>

<style lang="css" scoped>
.select_location {
    display: inline-block !important;
    margin-left: 5px;
}

.selected_location > span {
    color: #000;
    padding-top: 3px;
    padding-left: 5px;
    position: absolute;
    max-width: 100%;
    overflow-x: hidden;
    cursor: pointer;
}

.selected_location:after {
    border-left: 13px solid;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -13px;
}

.selected_location {
    width: 100px;
    height: 26px;
    display: inline-block;
    position: relative;
}

.selected_location_even {
    color: #afafaf;
    background: #afafaf;
}

.selected_location_even:after {
    color: #afafaf;
}

.selected_location_odd {
    color: #efefef;
    background: #efefef;
}
.selected_location_odd:after {
    color: #efefef;
}
.filter-btn {
    width: auto !important;
    height: 26px;
    padding: 0px 5px;
    background: #f3f3f3;
    color: #333;
    border: 1px solid #dedede;
}

/* .multiselect {
    height: 40px;
    display: flex;
    position: relative;
    bottom: 5px;
} */
.multiselect__tags {
    max-width: 400px;
    overflow: auto;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 40px 0px 4px !important;
    font-size: 12px !important;
}

.multiselect__tags-wrap{
    display: flex !important;
}
  
.multiselect__option--selected {
      background: #d7eafea3;
      color: #35495e;
      font-weight: 700;
}
</style>
