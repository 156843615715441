import Keycloak from 'keycloak-js'
import axios from 'axios'
(function( window, undefined ) {
    // Polyfills - Start
    (function () {

        if ( typeof window.CustomEvent === "function" ) return false;
      
        function CustomEvent ( event, params ) {
          params = params || { bubbles: false, cancelable: false, detail: null };
          var evt = document.createEvent( 'CustomEvent' );
          evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
          return evt;
         }
      
        window.CustomEvent = CustomEvent;
    })();
    // Polyfills - Ends

    var checkStartWith = function (thisObj,search,pos){
        pos = !pos || pos < 0 ? 0 : +pos;
        return thisObj.substring(pos, pos + search.length) === search;
    };
      
    var getHashParameterByName=function (name, url) {
          if (!url) url = window.location.href;
          url = url.replace(/%3D/gi, '=');
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    var KeycloakImpl = function () {
        if (!(this instanceof KeycloakImpl)) {
            window.keycloakImplObj = new KeycloakImpl();
            return window.keycloakImplObj;
        }

        var kcImpl = this;

        /**************** Variables - Start **********/
        kcImpl.METADATA = null;
        kcImpl.META_URL = '/api' +(window.baseUrl ? window.baseUrl : '')+'/kcmd';
        kcImpl.AUTHENTICATED = false;
        kcImpl.SSO_CHECK_RESPONSE = false;
        kcImpl.SESSION_TIME = 1000*2100;
        kcImpl.kcmdResponse = false;
        kcImpl.Original_XMLHttpRequest = XMLHttpRequest;
        kcImpl.authStartTime;
        /**************** Variables - End **********/

        function createPromise() {
            var p = {
                setSuccess: function(result) {
                    p.success = true;
                    p.result = result;
                    if (p.successCallback) {
                        p.successCallback(result);
                    }
                },

                setError: function(result) {
                    p.error = true;
                    p.result = result;
                    if (p.errorCallback) {
                        p.errorCallback(result);
                    }
                },

                promise: {
                    success: function(callback) {
                        if (p.success) {
                            callback(p.result);
                        } else if (!p.error) {
                            p.successCallback = callback;
                        }
                        return p.promise;
                    },
                    error: function(callback) {
                        if (p.error) {
                            callback(p.result);
                        } else if (!p.success) {
                            p.errorCallback = callback;
                        }
                        return p.promise;
                    }
                }
            }
            return p;
        }
        var loadKCMeta = function(setIdentifier){
            var promise = createPromise();
            var req = new kcImpl.Original_XMLHttpRequest();
            req.open('GET', kcImpl.META_URL, true);
            req.setRequestHeader('Accept', 'application/json');
            req.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
            req.setRequestHeader("Pragma", "no-cache");
            req.setRequestHeader("Expires", "-1");
            req.setRequestHeader("Authorization", "Bearer not-required");            
            req.onreadystatechange = function () {
                if (req.readyState == 4) {
                    if (req.status == 200) {
                        kcImpl.METADATA = JSON.parse(req.responseText).payload;
                        kcImpl.METADATA.authServerUrl = kcImpl.METADATA['auth-server-url'];
                        kcImpl.METADATA.url = kcImpl.METADATA.authServerUrl;
                        kcImpl.METADATA.clientId = kcImpl.METADATA['resource'];
                        kcImpl.METADATA.clientSecret = (kcImpl.METADATA['credentials'] || {})['secret'];
                        kcImpl.METADATA.checkLoginIframe = true;
                        kcImpl.kcmdResponse = true;
                        promise.setSuccess();
                    } else {
                        promise.setError(req.responseText);
                        console.log("error");
                        var event = new CustomEvent('application-down');
                        document.dispatchEvent(event);
                    }
                }
            }
            req.send();
            return promise.promise;
        }

        var updateXMLHTTPRequestForToken = function(){
            var _open = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function(){
                this.resourceUrl = arguments[1];
                _open.apply(this, arguments);
            }
            var _send = XMLHttpRequest.prototype.send;
            XMLHttpRequest.prototype.send = function(){
                var thisXhr = this;
                var thisXhrArguments = arguments;
                //var url = thisXhrArguments[1];
                var url = this.resourceUrl.replace("http://","https://");                
                if((url == kcImpl.META_URL || checkStartWith(url,"https://")) && !checkStartWith(url,"https://"+window.location.host)){
                    return _send.apply(thisXhr, thisXhrArguments);
                }
                if(checkStartWith(url,"https://"+window.location.host+"/auth")){
                    kcImpl.authStartTime = new Date(new Date().getTime() + kcImpl.SESSION_TIME).getTime();
                    return _send.apply(thisXhr, thisXhrArguments);
                }
                if(!kcImpl.SSO_CHECK_RESPONSE){
                    setTimeout(function(){
                        thisXhr.send.apply(thisXhr, thisXhrArguments);
                    },1000);
                    return;
                }
                if(kcImpl.AUTHENTICATED){                    
                    updateToken().success(function(token){
                        //console.log('------------kcImpl.AUTHENTICATED-----');
                        thisXhr.setRequestHeader("Authorization", "Bearer " + token);
                        thisXhr.setRequestHeader("selectedrole", kcImpl?.userDetails?.selectedRole);
                        // console.log('kcImpl',kcImpl);
                        // console.log('thisXhr',thisXhr);
                        // console.log('thisXhrArguments',thisXhrArguments);
                        _send.apply(thisXhr, thisXhrArguments);
                    }).error(function(){
                        sendSessionTimeout()
                    }
                    );
                    return;
                }
                else{
                    kcImpl.keycloak.login();
                    setTimeout(function(){
                        thisXhr.send.apply(thisXhr, thisXhrArguments);
                    },1000);
                    return;
                }
            }
        }
        var updateToken = function(){
            var promise = createPromise();
            var updateTokenParam = 1;
            if(kcImpl.authStartTime < (new Date().getTime())){
                updateTokenParam = -1;
            }
            kcImpl.keycloak.updateToken(updateTokenParam).success(function(refreshed){
                promise.setSuccess(kcImpl.keycloak.token);
            }).error(function(){
                var event = new CustomEvent('session-timeout',{detail: {isSessionRefresh : false}});
                document.dispatchEvent(event);
                promise.setError();
            });
            return promise.promise;
        }
        
        kcImpl.updateToken=updateToken;
        
        var checkSSO = function(pageInitiate){
            kcImpl.keycloak = Keycloak(kcImpl.METADATA);
            kcImpl.keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false}).success(function(authenticated) {
                if(!authenticated){
                    kcImpl.keycloak.login();
                }
                kcImpl.SSO_CHECK_RESPONSE = true;
                kcImpl.AUTHENTICATED = authenticated;
                getUserDetails(pageInitiate);
                document.addEventListener("extend-session", function(e) { 
                    kcImpl.updateToken();
                });
            }).error(function() {
                console.log('failed to initialize');
            });
        }

        var getUserDetails = function(pageInitiate){
            axios.get('/api' +(window.baseUrl ? window.baseUrl : '')+'/user/me').then((response) => {
                kcImpl.userDetails = response.data.payload;
                pageInitiate();
            })
        }

        kcImpl.initiate = function(pageInitiate){
            updateXMLHTTPRequestForToken();
            if(kcImpl.METADATA == null){
                var metaPromise = loadKCMeta(true);
                metaPromise.success(function(){
                    checkSSO(pageInitiate);
                }).error(function(errordata){
                    console.error("Error getting keycloak client meta for login");
                });
            }
            else{
                checkSSO(pageInitiate);
            }
        }

        kcImpl.logout = function(){
            kcImpl.keycloak.logout();
        }

        function sendSessionTimeout(){
            var event = new CustomEvent('session-time-out');
            document.dispatchEvent(event);
        }

        kcImpl.refreshToken = function(){
            return updateToken();
        }
    }
    
    if ( typeof module === "object" && module && typeof module.exports === "object" ) {
        module.exports = KeycloakImpl;
    } else {
        window.KeycloakImpl = KeycloakImpl;

        if ( typeof define === "function" && define.amd ) {
            define( "keycloakImpl", [], function () { return KeycloakImpl; } );
        }
    }
})( window );

