<template>
    <span>
        <CButton v-for="(item, index) in params.buttons" :key="index"
            :size="item.size"
            :class="item.class"
            :style="item.style"
            @click="buttonClicked(item)"
            :title="item.title">
            <i v-if="item.icon" :class="item.icon.class"/>
            <span v-if="item.text">{{item.text}}</span>
        </CButton>
    </span>
</template>
<script>
export default {
    data: function () {
        return {
        };
    },
    beforeMount() {
    },
    methods:{
        buttonClicked(item){
            item.method(this.params.data, this.params);
        }
    }
}
</script>