import moment from "moment";
function leftPad(output, targetLength) {
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}
function getUQLNo(orderNo) {
  if (!orderNo) {
    return "";
  }
  return "UQL" + leftPad(orderNo, 10);
}
function getQLNo(orderNo) {
  if (!orderNo) {
    return "";
  }
  return "QL" + leftPad(orderNo, 10);
}
function getENQNo(orderNo) {
  if (!orderNo) {
    return "";
  }
  return "ENQ" + leftPad(orderNo, 10);
}
function getSONo(orderNo) {
  if (!orderNo) {
    return "";
  }
  return "SO" + leftPad(orderNo, 10);
}
function getSpecificationNo(sNO) {
  if (!sNO) {
    return "";
  }
  return "QU" + leftPad(sNO, 10);
}

let emailRegex = /\S+@\S+\.\S+/;
let mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
let phoneRegex = /^(\+\d{1,3}[- ]?)?\d{7,12}$/;
let pincodeRegex = /^[1-9]{1}[0-9]{5}$/;
const TYPES = {
  UQL: "UQL",
  QL: "QL",
  ENQUIRY: "ENQUIRY",
  SALES_ORDER: "SALES_ORDER",
  CUSTOM_PO: "CUSTOM_PO",
  PAYMENTS: "PAYMENTS",
};
function regexValidationWithRequired(value, regexfor, message) {
  let error = null;
  if (!value) {
    error = "Required.";
  } else if (regexfor.test(value) == false) {
    error = message;
  }
  return error;
}
function regexValidation(value, regexfor, message) {
  let error = null;
  if (value && regexfor.test(value) == false) {
    error = message;
  }
  return error;
}
function isSpecificationsDeliveryDetailsError(sodDetails) {
  let isError = false;
  let isChildError = false;
  let refSpecification = sodDetails.$refs.specifications;
  let refDeliveryDetails = refSpecification.$refs.deliveryDetails[0];
  if (isDeliveryDetailsError(refDeliveryDetails)) {
    isChildError = true;
    refDeliveryDetails.isErrorPresent = true;
  }
  if (isChildError) {
    isError = true;
    refSpecification.isErrorPresent[index] = true;
  }
  return isError;
}
function isSpecificationsError(
  sodDetails,
  onlyBasic,
  validateDelivaryDetails,
  isQuote
) {
  let isError = false;
  let refSpecification = sodDetails.$refs.specifications;
  for (let index = 0; index < refSpecification.specifications.length; index++) {
    let refProductDetail = refSpecification.$refs.productDetails[index];
    refProductDetail.productErrors = [];
    let products = refProductDetail.products;
    let isChildError = false;
    let isDelChildError = false;
    console.log("products.length Utils : ", products.length);
    if (!products || products.length < 1) {
      refProductDetail.productErrors.push("Atleast one product is required.");
      refProductDetail.isErrorPresent = true;
      isChildError = true;
    } else {
      let errors = [];
      for (let product of products) {
        let error = {};
        if (!product.productType) {
          error.productType = "Required.";
          isChildError = true;
        }
        if (!product.sku_id) {
          error.sku_id = "Required.";
          isChildError = true;
        }
        if (!onlyBasic) {
          if (!product.quantity) {
            error.quantity = "Required.";
            isChildError = true;
          } else if (product.quantity < 1) {
            error.quantity = "Invalid.";
            isChildError = true;
          }
          if (!product.salePrice) {
            error.salePrice = "Required.";
            isChildError = true;
          } else if (product.salePrice < 1) {
            error.salePrice = "Invalid.";
            isChildError = true;
          }
          if (!product.gst_type) {
            error.gst_type = "Required.";
            isChildError = true;
          }
          // if(!product.remarks){
          //     error.remarks = "Required.";
          //     isChildError = true;
          // }
        }
        errors.push(error);
      }
      refProductDetail.errors.products = errors;
      if (!onlyBasic) {
        let isOtherError = false;
        let otherDetails = refProductDetail.other_details;
        let error = {};
        error.discount = {};
        if (!otherDetails.discount.value) {
          error.discount = { value: "Required." };
          isOtherError = true;
        } else if (
          otherDetails.discount.value < 0 ||
          otherDetails.discount.value > 99
        ) {
          error.discount = { value: "Invalid." };
          isOtherError = true;
        }
        error.commission = {};
        if (
          parseFloat(otherDetails.commission.value) < 0 ||
          parseFloat(otherDetails.commission.value) > 99
        ) {
          error.commission = { value: "Invalid." };
          isOtherError = true;
        } else if (
          otherDetails.commission.value == undefined ||
          otherDetails.commission.value == null ||
          otherDetails.commission.value == ""
        ) {
          if (
            otherDetails.commission.value < 0 &&
            otherDetails.commission.value > 99
          ) {
            error.commission = { value: "Required." };
            isOtherError = true;
          }
        }
        error.freight_type = {};
        if (
          otherDetails?.freight_type?.str_value == null ||
          otherDetails?.freight_type?.str_value == undefined
        ) {
          error.freight_type.str_value = "Required.";
          isOtherError = true;
        }
        error.installation_type = {};
        if (
          otherDetails?.installation_type?.str_value == null ||
          otherDetails?.installation_type?.str_value == undefined
        ) {
          error.installation_type.str_value = "Required.";
          isOtherError = true;
        }
        error.freight = {};
        if (
          otherDetails?.freight?.value_total == null ||
          otherDetails?.freight?.value_total == undefined
        ) {
          error.freight.value_total = "Required.";
          isOtherError = true;
        } else if (parseFloat(otherDetails?.freight?.value_total) < 0) {
          error.freight.value_total = "Invalid.";
          isOtherError = true;
        } else if (
          !otherDetails?.freight?.gst_type &&
          parseFloat(otherDetails?.freight?.value_total) > 0
        ) {
          error.freight.gst_type = "Required.";
          isOtherError = true;
        }
        error.installation_labour = {};
        if (
          otherDetails?.installation_labour?.value_total == null ||
          otherDetails?.installation_labour?.value_total == undefined
        ) {
          error.installation_labour.value_total = "Required.";
          isOtherError = true;
        } else if (
          parseFloat(otherDetails?.installation_labour?.value_total) < 0
        ) {
          error.installation_labour.value_total = "Invalid.";
          isOtherError = true;
        } else if (
          !otherDetails?.installation_labour?.gst_type &&
          parseFloat(otherDetails?.installation_labour?.value_total) > 0
        ) {
          error.installation_labour.gst_type = "Required.";
          isOtherError = true;
        }
        error.installation_material = {};
        if (
          otherDetails?.installation_material?.value_total == null ||
          otherDetails?.installation_material?.value_total == undefined
        ) {
          error.installation_material.value_total = "Required.";
          isOtherError = true;
        } else if (
          parseFloat(otherDetails?.installation_material?.value_total) < 0
        ) {
          error.installation_material.value_total = "Invalid.";
          isOtherError = true;
        } else if (
          !otherDetails?.installation_material?.gst_type &&
          parseFloat(otherDetails?.installation_material?.value_total) > 0
        ) {
          error.installation_material.gst_type = "Required.";
          isOtherError = true;
        }
        error.fright_others = {};
        if (parseFloat(otherDetails?.fright_others?.total) < 0) {
          error.fright_others.total = "Invalid.";
          isOtherError = true;
        } else if (
          otherDetails?.fright_others?.total == undefined ||
          otherDetails?.fright_others?.total == null ||
          otherDetails?.fright_others?.total == ""
        ) {
          if (otherDetails?.fright_others?.total < 0) {
            error.fright_others.total = "Required.";
            isOtherError = true;
          }
        }
        error.installation_others = {};
        if (parseFloat(otherDetails?.installation_others?.total) < 0) {
          error.installation_others.total = "Invalid.";
          isOtherError = true;
        } else if (
          otherDetails?.installation_others?.total == undefined ||
          otherDetails?.installation_others?.total == null ||
          otherDetails?.installation_others?.total == ""
        ) {
          if (otherDetails?.installation_others?.total < 0) {
            error.installation_others.total = "Required.";
            isOtherError = true;
          }
        }
        error.expenses_others = {};
        if (parseFloat(otherDetails?.expenses_others?.total) < 0) {
          error.expenses_others.total = "Invalid.";
          isOtherError = true;
        } else if (
          otherDetails?.expenses_others?.total == undefined ||
          otherDetails?.expenses_others?.total == null ||
          otherDetails?.expenses_others?.total == ""
        ) {
          if (otherDetails?.expenses_others?.total < 0) {
            error.expenses_others.total = "Required.";
            isOtherError = true;
          }
        }
        error.roundup = {};
        if (otherDetails?.roundup?.total == undefined) {
          error.roundup.total = "Required.";
          isOtherError = true;
        } else if (parseFloat(otherDetails?.roundup?.total) < 0) {
          error.roundup.total = "Invalid.";
          isOtherError = true;
        }
        error.finalBillingAmount = {};
        if (otherDetails?.finalBillingAmount?.value == undefined) {
          error.finalBillingAmount.value = "Required.";
          isOtherError = true;
        } else if (parseFloat(otherDetails?.finalBillingAmount?.value) < 0) {
          error.finalBillingAmount.value = "Invalid.";
          isOtherError = true;
        }
        console.log("error : ", error);
        if (isOtherError) {
          refProductDetail.isOtherErrorPresent = true;
          isChildError = true;
        }
        refProductDetail.errors.other_details = error;
      }
    }
    if (!onlyBasic) {
      let refPaymentTerms =
        refSpecification.$refs.specificationOtherDetails[index];
      let isPaymentError = false;
      let specs = refPaymentTerms.specification;
      refPaymentTerms.paymentErrors = [];
      refPaymentTerms.warrantyError = null;
      if (!specs.warranty) {
        refPaymentTerms.paymentErrors.push("Warranty is required.");
        refPaymentTerms.warrantyError = "required.";
        refPaymentTerms.isErrorPresent = true;
        isPaymentError = true;
      }
      let paymentTerms = refPaymentTerms.paymentTerms;
      console.log("refPaymentTerms.length Utils : ", paymentTerms.length);
      if (!paymentTerms || paymentTerms.length < 1) {
        refPaymentTerms.paymentErrors.push(
          "Atleast one payment term is required."
        );
        refPaymentTerms.isErrorPresent = true;
        isPaymentError = true;
      } else {
        let errors = [];
        let paymentSlabs = [];
        let paymentPercentage = 0;
        for (let term of paymentTerms) {
          let error = {};
          if (!term.percentage) {
            error.percentage = "Required.";
            isPaymentError = true;
          } else {
            paymentPercentage += parseFloat(term.percentage);
          }
          if (!term.payment_phase_id) {
            error.payment_phase_id = "Required.";
            isPaymentError = true;
          } else {
            if (paymentSlabs.includes(term.payment_phase_id)) {
              error.payment_phase_id = "Payment Slab already used.";
              isPaymentError = true;
            } else {
              paymentSlabs.push(term.payment_phase_id);
            }
          }
          errors.push(error);
        }
        if (paymentPercentage < 100) {
          refPaymentTerms.paymentErrors.push(
            "Payment term percentage should not be less than 100%."
          );
          refPaymentTerms.isErrorPresent = true;
          isPaymentError = true;
        }
        if (paymentPercentage > 100) {
          refPaymentTerms.paymentErrors.push(
            "Payment term percentage should not be greater than 100%."
          );
          refPaymentTerms.isErrorPresent = true;
          isPaymentError = true;
        }
        refPaymentTerms.errors.paymentTerms = errors;
        console.log("paymentPercentage : ", paymentPercentage);
      }

      if (isPaymentError) {
        refPaymentTerms.isErrorPresent = true;
        isError = true;
        refSpecification.isErrorPresent[index] = true;
      }
    }
    if (validateDelivaryDetails) {
      let refDeliveryDetails = refSpecification.$refs.deliveryDetails[index];
      if (isContactError(refDeliveryDetails, true, false, isQuote)) {
        isDelChildError = true;
        isError = true;
        refSpecification.isErrorPresent[index] = true;
        refDeliveryDetails.isErrorPresent = true;
      }
    }
    if (isChildError) {
      refProductDetail.isErrorPresent = true;
      isError = true;
      refSpecification.isErrorPresent[index] = true;
    }
  }
  return isError;
}

function isDeliveryDetailsError(contactRef) {
  let isError = false;
  let errors = contactRef.errors;
  for (let index = 0; index < contactRef.contactDetails.length; index++) {
    let contactDetail = contactRef.contactDetails[index];
    if (!contactDetail.delivery_type) {
      isError = true;
      errors[index].delivery_type = "Required";
    }
    if (!contactDetail.unloading_type) {
      isError = true;
      errors[index].unloading_type = "Required";
    }
    if (!contactDetail.unloading_location) {
      isError = true;
      errors[index].unloading_location = "Required";
    } else {
      if (
        contactDetail.unloading_location == "P" &&
        !contactDetail.podium_floor
      ) {
        isError = true;
        errors[index].podium_floor = "Required";
      }
    }
  }
  if (isError) {
    contactRef.isErrorPresent = true;
  }
  return isError;
}
function isContactError(
  contactRef,
  isNotRequired,
  skipAddress = false,
  isQuote = false
) {
  let isError = false;
  let errors = contactRef.errors;
  for (let index = 0; index < contactRef.contactDetails.length; index++) {
    let contactDetail = contactRef.contactDetails[index];
    if (!isNotRequired) {
      if (!contactDetail.name || !contactDetail.title) {
        errors[index].name = "Required.";
        isError = true;
      }
      if (!contactDetail.designation) {
        errors[index].designation = "Required.";
        isError = true;
      }
    }
    errors[index].mobile = [];
    for (let mobile of contactDetail.mobile) {
      let errorMessage = "";
      if (isNotRequired) {
        errorMessage = regexValidation(mobile, mobileRegex, "Invalid Mobile.");
      } else {
        errorMessage = regexValidationWithRequired(
          mobile,
          mobileRegex,
          "Invalid Mobile."
        );
      }
      errors[index].mobile.push(errorMessage);
      if (errorMessage) {
        isError = true;
      }
    }
    errors[index].email = [];
    for (let email of contactDetail.email) {
      let errorMessage = "";
      if (isNotRequired) {
        errorMessage = regexValidation(email, emailRegex, "Invalid Email.");
      } else {
        errorMessage = regexValidationWithRequired(
          email,
          emailRegex,
          "Invalid Email."
        );
      }
      errors[index].email.push(errorMessage);
      if (errorMessage) {
        isError = true;
      }
    }
    errors[index].phone = [];
    for (let phone of contactDetail.phone) {
      let errorMessage = regexValidation(phone, phoneRegex, "Invalid Phone.");
      errors[index].phone.push(errorMessage);
      if (errorMessage) {
        isError = true;
      }
    }
    if (!skipAddress) {
      let addressValidation = validateAddresses(contactDetail.address, isQuote);
      errors[index].address = addressValidation[0];
      if (addressValidation[1]) {
        isError = true;
      }
    }
  }
  if (isError) {
    contactRef.isErrorPresent = true;
  }
  return isError;
}
function validateAddresses(addresses, isQuote = false) {
  let errors = [];
  let isError = false;
  for (let address of addresses) {
    let error = {};
    if (!address.line1 && !isQuote) {
      error.line1 = "Required.";
      isError = true;
    }
    if (!address.line2 && !isQuote) {
      error.line2 = "Required.";
      isError = true;
    }
    if (!address.city) {
      error.city = "Required.";
      isError = true;
    }
    if (!address.state) {
      error.state = "Required.";
      isError = true;
    }
    if (!address.country && !isQuote) {
      error.country = "Required.";
      isError = true;
    }
    let pinerror = regexValidationWithRequired(
      address.pincode,
      pincodeRegex,
      "Invalid Pincode."
    );
    if (pinerror && !isQuote) {
      error.pincode = pinerror;
      isError = true;
    }
    errors.push(error);
  }
  return [errors, isError];
}
function isOrgDetailsError(
  sodDetails,
  eitherMobileOrEmail,
  validateAddressAndContact,
  validateFinancialDetails = false
) {
  let isError = false;
  if (!sodDetails.$refs.leadDetails.leadDetails.ul_name) {
    sodDetails.$refs.leadDetails.errors.ul_name = "Required.";
    isError = true;
  }
  if (!sodDetails.$refs.leadDetails.leadDetails.domain) {
    sodDetails.$refs.leadDetails.errors.domain = "Required.";
    isError = true;
  }
  if (!sodDetails.$refs.leadDetails.leadDetails.organization_zone) {
    sodDetails.$refs.leadDetails.errors.organization_zone = "Required.";
    isError = true;
  }
  sodDetails.$refs.leadDetails.isBillingErrorPresent = false;
  if (validateFinancialDetails) {
    let isBillingError = false;
    if (!sodDetails.$refs.leadDetails.leadDetails.gst_number) {
      sodDetails.$refs.leadDetails.errors.gst_number = "Required.";
      isError = true;
      isBillingError = true;
    }
    if (!sodDetails.$refs.leadDetails.leadDetails.pan) {
      sodDetails.$refs.leadDetails.errors.pan = "Required.";
      isError = true;
      isBillingError = true;
    }
    let error = {};
    if (!sodDetails.$refs.leadDetails.leadDetails.billing_address.line1) {
      error.line1 = "Required.";
      isError = true;
      isBillingError = true;
    }
    if (!sodDetails.$refs.leadDetails.leadDetails.billing_address.line2) {
      error.line2 = "Required.";
      isError = true;
      isBillingError = true;
    }
    if (!sodDetails.$refs.leadDetails.leadDetails.billing_address.city) {
      error.city = "Required.";
      isError = true;
      isBillingError = true;
    }
    if (!sodDetails.$refs.leadDetails.leadDetails.billing_address.state) {
      error.state = "Required.";
      isError = true;
      isBillingError = true;
    }
    if (!sodDetails.$refs.leadDetails.leadDetails.billing_address.country) {
      error.country = "Required.";
      isError = true;
      isBillingError = true;
    }
    let pinerror = regexValidationWithRequired(
      sodDetails.$refs.leadDetails.leadDetails.billing_address.pincode,
      pincodeRegex,
      "Invalid Pincode."
    );
    if (pinerror) {
      error.pincode = pinerror;
      isError = true;
      isBillingError = true;
    }
    sodDetails.$refs.leadDetails.errors.billing_address = error;
    if (isBillingError) {
      sodDetails.$refs.leadDetails.isBillingErrorPresent = true;
    }
  }

  // if(!sodDetails.$refs.leadDetails.leadDetails.name || !sodDetails.$refs.leadDetails.leadDetails.title){
  //     sodDetails.$refs.leadDetails.errors.name = "Required.";
  //     isError = true;
  // }
  // sodDetails.$refs.leadDetails.errors.mobile = [];
  // let mobileError = false;
  // for(let mobile of sodDetails.$refs.leadDetails.leadDetails.mobile){
  //     let errorMessage = regexValidationWithRequired(mobile,mobileRegex, "Invalid Mobile.");
  //     sodDetails.$refs.leadDetails.errors.mobile.push(errorMessage);
  //     if(errorMessage){
  //         isError = true;
  //         mobileError = true;
  //     }
  // }
  // sodDetails.$refs.leadDetails.errors.email = [];
  // if(!eitherMobileOrEmail || (eitherMobileOrEmail && mobileError)){
  //     for(let email of sodDetails.$refs.leadDetails.leadDetails.email){
  //         let errorMessage = regexValidationWithRequired(email,emailRegex, "Invalid Email.");
  //         sodDetails.$refs.leadDetails.errors.email.push(errorMessage);
  //         if(errorMessage){
  //             isError = true;
  //         }
  //     }
  // }
  // else{
  //     for(let email of sodDetails.$refs.leadDetails.leadDetails.email){
  //         let errorMessage = regexValidation(email,emailRegex, "Invalid Email.");
  //         sodDetails.$refs.leadDetails.errors.email.push(errorMessage);
  //         if(errorMessage){
  //             isError = true;
  //         }
  //     }
  // }
  // sodDetails.$refs.leadDetails.errors.phone = [];
  // for(let phone of sodDetails.$refs.leadDetails.leadDetails.phone){
  //     let errorMessage = regexValidation(phone,phoneRegex, "Invalid Phone.");
  //     sodDetails.$refs.leadDetails.errors.phone.push(errorMessage);
  //     if(errorMessage){
  //         isError = true;
  //     }
  // }
  if (validateAddressAndContact) {
    let addressValidation = validateAddresses(
      sodDetails.$refs.leadDetails.leadDetails.address
    );
    sodDetails.$refs.leadDetails.errors.address = addressValidation[0];
    if (addressValidation[1]) {
      isError = true;
      sodDetails.$refs.leadDetails.isAddressErrorPresent = true;
    }
    if (
      isContactError(
        sodDetails.$refs.leadDetails.$refs.contactDetails,
        false,
        false
      )
    ) {
      isError = true;
    }
  } else {
    if (
      isContactError(
        sodDetails.$refs.leadDetails.$refs.contactDetails,
        true,
        true
      )
    ) {
      isError = true;
    }
  }
  if (isError) {
    sodDetails.$refs.leadDetails.isErrorPresent = true;
  }
  return isError;
}
function isBasicErrorUQL(sodDetails) {
  console.log("Validation Check.......", sodDetails);
  let isError = false;
  sodDetails.$refs.basicDetails.errors = {};
  console.log("Assigned to UTILS:", sodDetails.sodDetails.assigned_to);
  if (!sodDetails.sodDetails.assigned_to) {
    sodDetails.$refs.basicDetails.errors.assigned_to = "Required.";
    isError = true;
    console.log("IS ERROR 1", isError);
  }
  if (!sodDetails.sodDetails.source) {
    sodDetails.$refs.basicDetails.errors.source = "Required.";
    isError = true;
    console.log("IS ERROR 2", isError);
  }
  if (!sodDetails.sodDetails.created_at) {
    sodDetails.$refs.basicDetails.errors.created_at = "Required.";
    isError = true;
    console.log("IS ERROR 3", isError);
  }
  if (!sodDetails.sodDetails.so_location) {
    sodDetails.$refs.basicDetails.errors.so_location = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.lead_type) {
    sodDetails.$refs.basicDetails.errors.lead_type = "Required.";
    isError = true;
    console.log("IS ERROR 4", isError);
  }
  if (!sodDetails.sodDetails.lead_name || !sodDetails.sodDetails.title) {
    sodDetails.$refs.basicDetails.errors.lead_name = "Required.";
    isError = true;
    console.log("IS ERROR 5", isError);
  }
  if (!sodDetails.sodDetails.mobile) {
    sodDetails.$refs.basicDetails.errors.mobile = "Required.";
    isError = true;
    console.log("IS ERROR 6", isError);
  }
  if (sodDetails.sodDetails.mobile) {
    let errorMessage = regexValidation(
      sodDetails.sodDetails.mobile,
      mobileRegex,
      "Invalid Mobile."
    );
    sodDetails.$refs.basicDetails.errors.mobile = errorMessage;
    if (errorMessage != null) {
      isError = true;
      console.log("IS ERROR 7", isError);
    }
  }
  if (sodDetails.sodDetails.email) {
    let errorMessage = regexValidation(
      sodDetails.sodDetails.email,
      emailRegex,
      "Invalid Email."
    );
    sodDetails.$refs.basicDetails.errors.email = errorMessage;
    if (errorMessage != null) {
      isError = true;
      console.log("IS ERROR 8", isError);
    }
  }

  let contactRef = sodDetails.$refs.leadDetails.$refs.contactDetails;
  for (let index = 0; index < contactRef.contactDetails.length; index++) {
    let contactDetail = contactRef.contactDetails[index];
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.mobile = [];
    for (let mobile of contactDetail.mobile) {
      let errorMessage = regexValidation(
        mobile,
        mobileRegex,
        "Invalid Mobile."
      );
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.mobile.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        console.log("IS ERROR 9", isError);
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.email = [];
    for (let email of contactDetail.email) {
      let errorMessage = regexValidation(email, emailRegex, "Invalid Email.");
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.email.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        console.log("IS ERROR 10", isError);
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.phone = [];
    for (let phone of contactDetail.phone) {
      let errorMessage = regexValidation(phone, phoneRegex, "Invalid Phone.");
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.phone.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        console.log("IS ERROR 11", isError);
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
  }
  console.log("IS ERROR", isError);
  return isError;
}
function isBasicErrorQL(sodDetails, validateSaleType = true) {
  let isError = false;
  sodDetails.$refs.basicDetails.errors = {};
  if (!sodDetails.sodDetails.assigned_to) {
    sodDetails.$refs.basicDetails.errors.assigned_to = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.sales_executive) {
    sodDetails.$refs.basicDetails.errors.sales_executive = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.source) {
    sodDetails.$refs.basicDetails.errors.source = "Required.";
    isError = true;
  }
  /*  else {
        if(sodDetails.sodDetails.source != 'Dealer' && !sodDetails.sodDetails.assigned_to_dealer){
            sodDetails.$refs.basicDetails.errors.assigned_to_dealer = "Required.";
            isError = true;
        } 
    } */
  if (!sodDetails.sodDetails.so_location) {
    sodDetails.$refs.basicDetails.errors.so_location = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.order_type) {
    sodDetails.$refs.basicDetails.errors.order_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.sale_type && validateSaleType) {
    sodDetails.$refs.basicDetails.errors.sale_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.enquiry_stage) {
    sodDetails.$refs.basicDetails.errors.enquiry_stage = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.enquiry_type) {
    sodDetails.$refs.basicDetails.errors.enquiry_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.created_at) {
    sodDetails.$refs.basicDetails.errors.created_at = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.lead_name || !sodDetails.sodDetails.title) {
    sodDetails.$refs.basicDetails.errors.lead_name = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.mobile) {
    sodDetails.$refs.basicDetails.errors.mobile = "Required.";
    isError = true;
  }
  if (sodDetails.sodDetails.mobile) {
    let errorMessage = regexValidation(
      sodDetails.sodDetails.mobile,
      mobileRegex,
      "Invalid Mobile."
    );
    sodDetails.$refs.basicDetails.errors.mobile = errorMessage;
    if (errorMessage != null) {
      isError = true;
    }
  }
  if (sodDetails.sodDetails.email) {
    let errorMessage = regexValidation(
      sodDetails.sodDetails.email,
      emailRegex,
      "Invalid Email."
    );
    sodDetails.$refs.basicDetails.errors.email = errorMessage;
    if (errorMessage != null) {
      isError = true;
    }
  }
  if (!sodDetails.sodDetails.lead_type) {
    sodDetails.$refs.basicDetails.errors.lead_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.lead_status) {
    sodDetails.$refs.basicDetails.errors.lead_status = "Required.";
    isError = true;
  }

  let contactRef = sodDetails.$refs.leadDetails.$refs.contactDetails;
  for (let index = 0; index < contactRef.contactDetails.length; index++) {
    let contactDetail = contactRef.contactDetails[index];
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.mobile = [];
    for (let mobile of contactDetail.mobile) {
      let errorMessage = regexValidation(
        mobile,
        mobileRegex,
        "Invalid Mobile."
      );
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.mobile.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.email = [];
    for (let email of contactDetail.email) {
      let errorMessage = regexValidation(email, emailRegex, "Invalid Email.");
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.email.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
    sodDetails.$refs.leadDetails.$refs.contactDetails.errors.phone = [];
    for (let phone of contactDetail.phone) {
      let errorMessage = regexValidation(phone, phoneRegex, "Invalid Phone.");
      sodDetails.$refs.leadDetails.$refs.contactDetails.errors.phone.push(
        errorMessage
      );
      if (errorMessage) {
        isError = true;
        sodDetails.$refs.leadDetails.isErrorPresent = true;
        sodDetails.$refs.leadDetails.$refs.contactDetails.isErrorPresent = true;
      }
    }
  }
  return isError;
}
function isBasicError(sodDetails, validateSaleType = true) {
  let isError = false;
  sodDetails.$refs.basicDetails.errors = {};
  if (!sodDetails.sodDetails.assigned_to) {
    sodDetails.$refs.basicDetails.errors.assigned_to = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.sales_executive) {
    sodDetails.$refs.basicDetails.errors.sales_executive = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.source) {
    sodDetails.$refs.basicDetails.errors.source = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.so_location) {
    sodDetails.$refs.basicDetails.errors.so_location = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.order_type) {
    sodDetails.$refs.basicDetails.errors.order_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.sale_type && validateSaleType) {
    sodDetails.$refs.basicDetails.errors.sale_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.enquiry_stage) {
    sodDetails.$refs.basicDetails.errors.enquiry_stage = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.enquiry_type) {
    sodDetails.$refs.basicDetails.errors.enquiry_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.created_at) {
    sodDetails.$refs.basicDetails.errors.created_at = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.lead_name || !sodDetails.sodDetails.title) {
    sodDetails.$refs.basicDetails.errors.lead_name = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.mobile && !sodDetails.sodDetails.email) {
    if (!sodDetails.sodDetails.mobile) {
      sodDetails.$refs.basicDetails.errors.mobile = "Required.";
      isError = true;
    }
    if (!sodDetails.sodDetails.email) {
      sodDetails.$refs.basicDetails.errors.email = "Required.";
      isError = true;
    }
  } else {
    if (sodDetails.sodDetails.mobile) {
      let errorMessage = regexValidation(
        sodDetails.sodDetails.mobile,
        mobileRegex,
        "Invalid Mobile."
      );
      sodDetails.$refs.basicDetails.errors.mobile = errorMessage;
      if (errorMessage != null) {
        isError = true;
      }
    }
    if (sodDetails.sodDetails.email) {
      let errorMessage = regexValidation(
        sodDetails.sodDetails.email,
        emailRegex,
        "Invalid Email."
      );
      sodDetails.$refs.basicDetails.errors.email = errorMessage;
      if (errorMessage != null) {
        isError = true;
      }
    }
  }
  if (!sodDetails.sodDetails.lead_type) {
    sodDetails.$refs.basicDetails.errors.lead_type = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.lead_status) {
    sodDetails.$refs.basicDetails.errors.lead_status = "Required.";
    isError = true;
  }
  if (!sodDetails.sodDetails.project_name) {
    sodDetails.$refs.basicDetails.errors.project_name = "Required.";
    isError = true;
  }
  return isError;
}
function getSODDetails(thisObj, validationType, isUpdate = true) {
  console.log("Utils This Object", thisObj);
  let isValid = true;
  // thisObj.$refs.leadDetails.isErrorPresent = false;
  // thisObj.$refs.leadDetails.isAddressErrorPresent = false;
  // thisObj.$refs.leadDetails.$refs.contactDetails.isErrorPresent = false;
  // thisObj.$refs.specifications.updateErrorPresent();
  // thisObj.$refs.plannedActions.isErrorPresent = false;
  switch (validationType) {
    case TYPES.UQL:
      console.log("TYPES.UQL : ", TYPES);
      if (isBasicErrorUQL(thisObj)) {
        isValid = false;
        console.log("This Inside Case1");
      }
      /* if(isOrgDetailsError(thisObj, true, false)){
                isValid = false;
            } */
      break;
    case TYPES.QL:
      console.log("TYPES.QL : ", TYPES);
      if (isBasicErrorQL(thisObj)) {
        isValid = false;
        console.log("Inside isBasicError QL");
      }
      /* if(isOrgDetailsError(thisObj, true, true)){
                isValid = false;
            }
            if(isSpecificationsError(thisObj, true, false)){
                isValid = false;
            } */
      break;
    case TYPES.ENQUIRY:
      console.log("TYPES.ENQUIRY : ", TYPES);
      if (isBasicErrorQL(thisObj)) {
        isValid = false;
      }
      if (isUpdate) {
        if (isBasicError(thisObj)) {
          isValid = false;
        }
        if (isOrgDetailsError(thisObj, true, true)) {
          isValid = false;
        }
        if (isSpecificationsError(thisObj, false, false, true)) {
          isValid = false;
        }
      }
      break;
    case TYPES.SALES_ORDER:
      console.log("TYPES.SALES_ORDER : ", TYPES);
      if (isBasicError(thisObj)) {
        isValid = false;
      }
      if (isOrgDetailsError(thisObj, true, true, true)) {
        isValid = false;
      }
      if (isSpecificationsError(thisObj, false, true)) {
        isValid = false;
      }
      if (isSpecificationsDeliveryDetailsError(thisObj)) {
        isValid = false;
      }
      break;
  }

  if (isValid) {
    thisObj.sodDetails = Object.assign(
      thisObj.sodDetails,
      thisObj.$refs.basicDetails.sodDetails
    );
    thisObj.sodDetails.leadDetails = thisObj.$refs.leadDetails.leadDetails;
    thisObj.sodDetails.contactDetails =
      thisObj.$refs.leadDetails.$refs.contactDetails.contactDetails;
    thisObj.sodDetails.plannedActions = thisObj.$refs.plannedActions.actions;
    thisObj.sodDetails.specifications =
      thisObj.$refs.specifications.getSpecifications();
    thisObj.sodDetails.created_by = window?.keycloakImplObj?.userDetails?.id;
  } else {
    thisObj.$forceUpdate();
    thisObj.$refs.basicDetails.$forceUpdate();
    thisObj.$refs.leadDetails.$forceUpdate();
    for (let address of thisObj.$refs.leadDetails.$refs.address) {
      address.$forceUpdate();
    }
    thisObj.$refs.leadDetails.$refs.contactDetails.$forceUpdate();
    for (let address of thisObj.$refs.leadDetails.$refs.contactDetails.$refs
      .address) {
      address.$forceUpdate();
    }
    thisObj.$refs.plannedActions.$forceUpdate();
    thisObj.$refs.specifications.forceUpdate();
  }
  return isValid;
}

function toCurreny(value) {
  if (!value) {
    return "-";
  }
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(value);
}

function toOtherCurrency(value, currencyCode, conversion_rate) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (typeof value !== "number") {
    return value;
  }
  if (!value) {
    return "-";
  }
  if (!conversion_rate) {
    return "-";
  }
  if (typeof conversion_rate === "string") {
    conversion_rate = parseFloat(conversion_rate);
  }
  if (typeof conversion_rate !== "number") {
    return conversion_rate;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });
  let convertedValue = value / conversion_rate;
  return formatter.format(convertedValue);
}

function toFixed(value) {
  if (!value) {
    return "-";
  }
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (typeof value !== "number") {
    return value;
  }
  return value.toFixed(2);
}

function getDate(date){
    if(!date){
        return '-';
    }
    return moment(date).format('DD MMM YYYY');
}

export {
    getUQLNo,
    getQLNo,
    getENQNo,
    getSODDetails,
    getSONo,
    TYPES,
    toCurreny,
    toOtherCurrency,
    toFixed,
    getSpecificationNo,
    regexValidation,
    getDate
}
