import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER, data)
}

function fetchList() {
    return APIBuilder.buildGetRequest(constants.SKUMASTER)
}

function getFinishedSKU() {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + '/finished/sku')
}

function fetchHierarchy() {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + '/hierarchy')
}
//First Stage For Tree View
function fetchTreeHierarchy() {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + '/treehierarchy')
}
function fetchHierarchyOnClick(id) {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + '/hierarchy/'+id)
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + "/" + id)
}

function getSKUNodes(id) {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + "/node/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.SKUMASTER + '/' + id, data)
}

function deleteSkumaster(id) {
    return APIBuilder.buildDeleteRequest(constants.SKUMASTER + '/' + id)
}
//To Test Api For QC Parameter
function getQCParameter(id) {
    return APIBuilder.buildGetRequest(constants.SKUMASTER + "/qcparamter/" + id)
}
function uploadBulk(fd) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER + '/bulk', fd)
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER+"/markActive", data)
}

function markHold(data) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER+"/markHold", data)
}

function markPhasedOut(data) {
    return APIBuilder.buildPostRequest(constants.SKUMASTER+"/markPhasedOut", data)
}

function getImage(id){
    return APIBuilder.buildGetRequest(constants.SKUMASTER + "/image/" + id)
}
function getSkuPriceHistory(id){
    return APIBuilder.buildGetRequest(constants.SKUMASTER + "/price/history/" + id);
}

export {
    add,
    fetchList,
    fetchHierarchy,
    details,
    update,
    deleteSkumaster,
    uploadBulk,
    markActive,
    markHold,
    markPhasedOut,
    markInactive,
    getFinishedSKU,
    getSKUNodes,
    getImage,
    getQCParameter,
    fetchHierarchyOnClick,
    fetchTreeHierarchy,
    getSkuPriceHistory
}