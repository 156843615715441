import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function fetchList() {    
    return APIBuilder.buildGetRequest(constants.APPLICATIONROLE)
}

function details(code) {
    return APIBuilder.buildGetRequest(constants.APPLICATIONROLE + "/" + code)
}
function add(data) {
    return APIBuilder.buildPostRequest(constants.APPLICATIONROLE, data)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.APPLICATIONROLE + '/' + id, data)
}

function deleteRole(id) {
    return APIBuilder.buildDeleteRequest(constants.APPLICATIONROLE + '/' + id)
}

function getPermissions(role){
    return APIBuilder.buildGetRequest(constants.APPLICATIONROLE + "/availablePermissions/" + role)
}

function getRolePermission(role){
    return APIBuilder.buildGetRequest(constants.APPLICATIONROLE + "/permissions/" + role)
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.APPLICATIONROLE+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.APPLICATIONROLE+"/markActive", data)
}

function markHold(data) {
    return APIBuilder.buildPostRequest(constants.APPLICATIONROLE+"/markHold", data)
}

function markPhasedOut(data) {
    return APIBuilder.buildPostRequest(constants.APPLICATIONROLE+"/markPhasedOut", data)
}


export {
    fetchList,
    details,
    add,
    update,
    deleteRole,
    getPermissions,
    getRolePermission,
    markActive,
    markHold,
    markPhasedOut,
    markInactive
}