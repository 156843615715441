<template lang="html">
    <div style="width:100%; height:600px">
        <ag-grid-vue
            style="width: 100%; height: 100%;"
            class="ag-theme-alpine"
            id="myGrid"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            :columnDefs="columnDefs"
            :pagination="true"
            :rowSelection="'multiple'"
            :paginationPageSize="10"
            :suppressRowClickSelection="true"
            :enableCellTextSelection="true"
            :modules="modules"
            :rowData="rowData"
            :rowClassRules="rowClassRules"></ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';

export default {
  name: "grid",
  components:{AgGridVue},
  props:['rowData','columnDefs', 'rowClassRules'],
  watch: { 
      rowData: function(newVal, oldVal) { // watch it
          this.sizeToFit();
      },
      columnDefs: function(newVal, oldVal) { // watch it
          this.sizeToFit();
      }
  },
  data () {
    return {
      // grid changes - start
      gridApi: null,
      gridColumnApi: null,
      modules: AllCommunityModules
      // grid changes - end
    }
  },
  created (){
    if(this.columnDefs && this.columnDefs.length > 0){
      for(let count = 0 ;count < this.columnDefs.length ; count++){
        if(!this.columnDefs[count].cellRenderer && !this.columnDefs[count].noDefaultRenderer){
          this.columnDefs[count].cellRenderer = (params) => {
            return params.data[params.colDef.field] ? params.data[params.colDef.field] :"-"
          }
        }
      }
    }
  },
  methods: {
    sizeToFit() {
        if(this.gridApi)
            this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    },
    onSelectionChanged(){
      this.$emit("selection-changed", this.gridApi.getSelectedRows());
    },
    redrawAllRows() {
      this.gridApi.redrawRows();
    },
   
  }
}
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
