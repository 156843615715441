<template lang="html">
  <div class="b-checkbox">
    <div class="form-check form-switch">
      <label class="form-check-label" v-bind:for="id" v-if="prelabel">{{prelabel}}</label>
      <input class="form-check-input " @change="update" :disabled="disabled" v-bind:id="id" type="checkbox" v-bind:class="disabled?'disabled':''" v-model="content">
      <label class="form-check-label" v-bind:for="id" v-if="label">{{label}}</label>
    </div>
  </div>
</template>

<script>

export default {
    name: 'BrdgeTextArea',
    props: ['value', 'label', 'disabled', 'prelabel'],
    data () {
        return {
          content: false,
          id:new Date().getTime()
        }
    },
    watch: { 
        value: function() { // watch it
          this.content = this.value
        }
    },
    methods: {
      update: function(){
        this.$emit('input', this.content);
      }
    },
  mounted: function(){
    this.content = this.value;
  }
}
</script>

<style lang="css" scoped>
.b-checkbox{
  display: inline-block;
  padding: 5px;
}
html:not([dir="rtl"]) .form-check{
  padding-left: 30px;
}
.form-check-input:focus{
  -webkit-box-shadow:unset;
  box-shadow:unset;
}
.form-check-input:not(.disabled){
  border-color: #428bff;
}
.form-check-input:checked{
  background-color: #428bff;
  border-color: #428bff;
}
html:not([dir="rtl"]) .form-check-input{
  /* margin-left: -30px; */
  /* cursor: pointer; */
  margin-left: -30px;
  cursor: pointer;
    margin-top: 1px;
}

html:not([dir="rtl"]) .form-check-input .quoteseprate{
  margin-left: -30px;
  cursor: pointer;
    margin-top: 1px;
}

html:not([dir=rtl]) .form-inline .form-check-input {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

.form-check{
  padding-left: 0px !important;
}

 #switch .b-checkbox .form-switch .form-check-input{
     width:2.5em!important;
     margin-left: -46px!important;
       cursor: pointer!important;
       margin-top: 4px!important;
   }

   .b-checkbox.nodesku .form-switch .form-check-input{
     width:2.5em!important;
     margin-left: 0px!important;
       cursor: pointer!important;
       margin-top: 4px!important;
   }

   .b-checkbox.nodesku .form-switch .form-check-label{
    margin-left: 50px!important;
   }
   
</style>
