import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import * as defaultConfig from '../config/toaster.config.json';
import Vue from 'vue';
let component;
let initialize = function(comp, config){
    component = comp;
    if(!config){
        config = defaultConfig.config;
    }
    Vue.use(DToaster, {
        presets: config,
        position: 'top-right', //toasts container position on the screen
        containerOffset: '45px', //toasts container offset from top/bottom of the screen
    });
}

let success = function(content){
    component.$dtoast.pop({
        preset: "success",
        content
      });
}

let error = function(content){
    component.$dtoast.pop({
        preset: "error",
        content
      });
}

let info = function(content){
    component.$dtoast.pop({
        preset: "info",
        content
      });
}

export {initialize, success, error, info}