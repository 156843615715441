import moment from "moment";
function leftPad(output, targetLength) {
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}
function getOrderNo(orderNo){
    return "ODO"+leftPad(orderNo, 7);
}

function dateToStr(date){
    if(!date){
        return "-";
    }
    return moment(date).format('DD/MM/YYYY');
}

const PartialToCSS = {
    'F':"btn greenbtn btntext",
    'Fulfilled':"btn greenbtn btntext",
    'FULFILLED':"btn greenbtn btntext",
    'P':"btn yellowbtn btntext",
    'Partial':"btn yellowbtn btntext",
    'PARTIAL':"btn yellowbtn btntext",
    'I':"btn redbtn btntext",
    'Incomplete':"btn redbtn btntext",
    'INCOMPLETE':"btn redbtn btntext",
    'N/A':"btn blackbtn btntext",
}

const PartialToCSSSpan = {
    'F':"text-success",
    'Fulfilled':"text-success",
    'FULFILLED':"text-success",
    'P':"text-warning",
    'Partial':"text-warning",
    'PARTIAL':"text-warning",
    'I':"text-danger",
    'Incomplete':"text-danger",
    'INCOMPLETE':"text-danger",
    'N/A':"text-dark",
}


function dateFromStr(date){
    return moment(date);
}
function toCurreny(value) {
    if (!value) {
      return "-";
    }
    if (typeof value === "string") {
      value = parseFloat(value);
    }
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(value);
  }

function getDeviationDays(likely_date_of_completion, completion_date){
    return moment(likely_date_of_completion).diff(moment(completion_date).startOf('day'), 'days');
}

function getActualDays(completion_date, likely_date_of_completion){
    console.log("getActualDays date 1 : ", completion_date);
    console.log("getActualDays date 2 : ", likely_date_of_completion);
    return moment(completion_date).diff(moment(likely_date_of_completion).startOf('day'), 'days');
}

function getDays(completion_date, start_date){
    console.log("getDays date 1 : ", completion_date);
    console.log("getDays date 2 : ", start_date);
    return moment(completion_date).diff(moment(start_date).startOf('day'), 'days');
}

export {
    getOrderNo,
    dateToStr,
    dateFromStr,
    PartialToCSS,
    PartialToCSSSpan,
    toCurreny,
    getDeviationDays,
    getActualDays,
    getDays
}