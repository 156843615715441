import $ from "jquery";
import * as utils from "@so/utils/utils";
export function getActionCell(config, reference) {
  let retConfig = Object.assign({}, config);
  retConfig.orderable = false;
  if (config.buttons) {
    let buttonfunctions = {};
    retConfig.render = function (data, type, row, settings) {
      let renderer = "";
      if (config.isVisible && config.isVisible == true) {
        if (row.specifications_count && row.specifications_count < 2) {
          return renderer;
        }
        if (row.po_status && row.po_status == "fulfilled") {
          return renderer;
        }
      }
      for (let button of config.buttons) {
        //if(button.toBeRestricted)
        buttonfunctions[button.title] = button.method;
        let btn_img_class = "";
        if (button.img?.class) {
          btn_img_class = button.img.class;
        }
        let disableClass = "";
        if (row.work_order_details) {
          if (row.work_order_details.length) {
            if (row.work_order_details[0].is_generated == true) {
              if (button.disableGenrateWo) {
                //disableClass = 'disabled';
                button.icon.class = "text-primary fa fa-eye";
              }
            } else {
              if (button.disableGenrateWo) {
                button.icon.class = "text-primary fa fa-calendar";
              }
              if (button.disableDownloadWo) {
                disableClass = "disabled";
              }
            }
          } else {
            if (button.disableGenrateWo) {
              disableClass = "disabled";
            }
            if (button.disableDownloadWo) {
              disableClass = "disabled";
            }
          }
        }
        if (row.pr_status) {
          if (button.toBeRestricted) {
            disableClass =
              (row.pr_status == "approved" || row.pr_status == "closed") &&
              row.has_new_products == false
                ? ""
                : "disabled";
          }
        }
        if (row.order_product?.is_planned_for_production) {
          if(row.order_production_plan_quantity == row.order_total_quantity){
            if (button.toBeRestricted) {
              disableClass = "hidden";
            }
          }
        }
        if (
          row.po_status &&
          row.po_status != "approved" &&
          row.po_status != "fulfilled"
        ) {
          //console.log("Button", button);
          if (button.isDocButton != undefined && button.isDocButton == true) {
            disableClass = "hidden";
          }
        }
        //For Node Map MS Status
        if (row.supply_status && row.supply_status == "MS") {
            if (button.isSupplyStatus == true) {
                disableClass = "disabled";
              }
       
        }
        //request Order Report
        if (row.is_child_bom_exists != true) {
          if (button.isBomButton == true) {
            disableClass = "hidden";
          }
        }
        //for connect Table
        if (row.picked_up_execution != true) {
          if (button.isMapButton == true) {
            disableClass = "hidden";
          }
          if (button.isOrderBtn == true) {
            disableClass = "disabled";
          }
        }
        if (row.picked_up_execution == true) {
          if (button.executionButton == true) {
            disableClass = "disabled";
          }
          if (button.editButton == true) {
            disableClass = "disabled";
          }
          if (button.timelineButton == true) {
            disableClass = "disabled";
          }
        }
        if (row.wo_traceble != true) {
          console.log("Action Datatable 1:",row);
          if (button.isTracebleButton == true) {
            disableClass = "hidden";
          }
        }
        if (row.wo_traceble == true) {
          console.log("Action Datatable 2:",row);
          if (button.isTracebleNotButton == true) {
            disableClass = "hidden";
          }
        }
        if (row.work_order_timeplan) {
        if (row.work_order_timeplan.status == 'COMPLETED') {
          if (button.isCOMPLETEDButton == true) {
            disableClass = "hidden";
          }
        }
      }
      if (row.order_details){
      if (row.order_details.order_type == 'work_order') {
        if (button.isWOButton == true) {
          disableClass = "disabled";
        }
      }
    }
        if (button.permission) {
          if (
            window?.keycloakImplObj?.userDetails?.permissions &&
            !window.keycloakImplObj.userDetails.permissions.includes(
              button.permission
            )
          ) {
            disableClass = "disabled";
          }
        }
        if(button.isAllocationBtn){
          if (row.allocated == true) {
            disableClass = "disabled";
          }
        }
        //console.log("row.po_status ::> ", row.po_status);
          //console.log("row.created_by ::> ", row.created_by);
          //console.log("window?.keycloakImplObj?.userDetails?.id ::>  ", window?.keycloakImplObj?.userDetails?.id);
          if(row.created_by != window?.keycloakImplObj?.userDetails?.id){
            if (button.isReassignButton != undefined && button.isReassignButton == true) {
              disableClass = "hidden";
            }
          }
          if (row.po_status && row.po_status != "for_approv") {
            if (button.isReassignButton != undefined && button.isReassignButton == true) {
              disableClass = "hidden";
            }
          }
          if (row.status) {
            if (row.status =='demand_submit') {
              if (button.demandButton) {
                //disableClass = 'disabled';
                button.icon.class = "text-primary fa fa-eye";
              }
            } else {
              if (button.demandButton) {
                button.icon.class = "text-primary fa fa-pencil";
              }
            }
          }
        renderer =
          renderer +
          `<button class="${btn_img_class} ${button.size} ${button.class} actionbuttons" style="${button.style}" title="${button.title}" ${disableClass}>`;
        if (button.iconDisabled?.class && disableClass == "disabled") {
          renderer = renderer + `<i class="${button.iconDisabled.class}"></i>`;
        } else if (button.icon?.class) {
          renderer = renderer + `<i class="${button.icon.class}"></i>`;
        }
        if (button.text) {
          renderer = renderer + `<span>${button.text}</span>`;
        }
        renderer = renderer + `</CButton>`;
      }
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.actionbuttons",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          console.log("el : ", el);
          console.log("ButtonsFunction : ", buttonfunctions);
          let table = $(el.closest("table")).DataTable();
          buttonfunctions[el[0].title](table.row(el.closest("tr")).data());
        }
      );
      return renderer;
    };
  }
  return retConfig;
}

export function getActionSecondaryCell(config, reference) {
  let retConfig = Object.assign({}, config);
  retConfig.orderable = false;
  if (config.buttons) {
    let buttonfunctions = {};
    retConfig.render = function (data, type, row, settings) {
      let renderer = "";
      if (config.isVisible && config.isVisible == true) {
        if (row.wo_count && row.wo_count < 1) {
          return renderer;
        }
      }
      for (let button of config.buttons) {
        //if(button.toBeRestricted)
        buttonfunctions[button.title] = button.method;
        let btn_img_class = "";
        if (button.img?.class) {
          btn_img_class = button.img.class;
        }
        let disableClass = "";
        if (row.work_order_details) {
          if (row.work_order_details.length) {
            if (row.work_order_details[0].is_generated == true) {
              if (button.disableGenrateWo) {
                //disableClass = 'disabled';
                button.icon.class = "text-primary fa fa-eye";
              }
            } else {
              if (button.disableGenrateWo) {
                button.icon.class = "text-primary fa fa-calendar";
              }
              if (button.disableDownloadWo) {
                disableClass = "disabled";
              }
            }
          } else {
            if (button.disableGenrateWo) {
              disableClass = "disabled";
            }
            if (button.disableDownloadWo) {
              disableClass = "disabled";
            }
          }
        }
        if (row.pr_status) {
          if (button.toBeRestricted) {
            disableClass =
              (row.pr_status == "approved" || row.pr_status == "closed") &&
              row.has_new_products == false
                ? ""
                : "disabled";
          }
        }
        if (button.permission) {
          if (
            window?.keycloakImplObj?.userDetails?.permissions &&
            !window.keycloakImplObj.userDetails.permissions.includes(
              button.permission
            )
          ) {
            disableClass = "disabled";
          }
        }
        renderer =
          renderer +
          `<button class="${btn_img_class} ${button.size} ${button.class} actionbuttons" style="${button.style}" title="${button.title}" ${disableClass}>`;
        if (button.icon?.class) {
          renderer = renderer + `<i class="${button.icon.class}"></i>`;
        }
        if (button.text) {
          renderer = renderer + `<span>${button.text}</span>`;
        }
        renderer = renderer + `</CButton>`;
      }
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.actionbuttons",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          console.log("el : ", el);
          console.log("ButtonsFunction : ", buttonfunctions);
          let table = $(el.closest("table")).DataTable();
          buttonfunctions[el[0].title](table.row(el.closest("tr")).data());
        }
      );
      return renderer;
    };
  }
  return retConfig;
}

export function cellClickRenderer(
  reference,
  gridRefFn,
  callback,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-arrow-down",
  buttonClass2 = "fa-arrow-up"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      cellText = `<button class="btn btn-sm btn-primary clickrenderer ${
        row.isRendered ? "hide" : ""
      }"><i class="fa ${buttonClass}"></i></button>
            <button class="btn btn-sm btn-primary clickrenderer2 ${
              row.isRendered ? "" : "hide"
            }"><i class="fa ${buttonClass2}"></i></button>
            ${cellText}`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.clickrenderer",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          let insertIndex = row.index() + 1;

          callback(row.data()).then((insertData) => {
            let gridref = reference.$refs[gridRefFn];
            gridref.data.draw = new Date().getTime();
            let i = 0;
            for (
              let insertCount = i;
              insertCount < insertData.length;
              insertCount++
            ) {
              insertData[insertCount].isChild = true;
              insertData[insertCount].rowIndex = row.index();
              //console.log("insertIndex+insertCount ; ", insertIndex+insertCount);
              //console.log("insertData[insertCount] : ", insertData[insertCount]);
              gridref.data.data.splice(
                insertIndex + insertCount,
                0,
                insertData[insertCount]
              );
            }
            let index = 0;
            for (
              let rowCounter = 0;
              rowCounter < gridref.data.data.length;
              rowCounter++
            ) {
              if (
                rowCounter != row.index() &&
                gridref.data.data[rowCounter].isRendered
              ) {
                index = rowCounter;
                for (
                  let j = rowCounter + 1;
                  j < gridref.data.data.length;
                  j++
                ) {
                  if (gridref.data.data[j].isChild) {
                    //console.log("Child index : ", j);
                    //console.log("index : ", index);
                    gridref.data.data[j].rowIndex = index;
                  } else {
                    break;
                  }
                }
              }
            }
            gridref.donotcallserver = true;
            table.clear().draw();
          });

          /* let insertData = callback(row.data());
                let gridref = reference.$refs[gridRefFn];
                gridref.data.draw = new Date().getTime();
                for(let insertCount = 0 ; insertCount < insertData.length ; insertCount++){
                    gridref.data.data.splice((insertIndex+insertCount), 0, insertData[insertCount]);
                }
                gridref.donotcallserver = true;
                table.clear().draw(); */
        }
      );
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.clickrenderer2",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          let gridref = reference.$refs[gridRefFn];
          gridref.data.data[row.index()].isRendered = false;
          let insertIndex = row.index() + 1;
          //console.log("insertIndex : ", insertIndex);

          let data = [];
          for (
            let rowCounter = 0;
            rowCounter < gridref.data.data.length;
            rowCounter++
          ) {
            //console.log("gridref.data.data[rowCounter] : ", gridref.data.data[rowCounter]);
            // if(gridref.data.data[rowCounter].isRendered == true){
            //     gridref.data.data[rowCounter].isRendered = false;
            // }
            if (
              rowCounter >= insertIndex &&
              gridref.data.data[rowCounter].isChild &&
              gridref.data.data[rowCounter].rowIndex == row.index()
            ) {
              //console.log("gridref.data.data[rowCounter] : ", rowCounter);
              continue;
            }
            // if(gridref.data.data[rowCounter].isChild){
            //     continue;
            // }
            data.push(gridref.data.data[rowCounter]);
          }
          gridref.data.draw = new Date().getTime();
          gridref.data.data = data;
          let index = 0;
          for (
            let rowCounter = 0;
            rowCounter < gridref.data.data.length;
            rowCounter++
          ) {
            if (
              rowCounter != row.index() &&
              gridref.data.data[rowCounter].isRendered
            ) {
              index = rowCounter;
              for (let j = rowCounter + 1; j < gridref.data.data.length; j++) {
                if (gridref.data.data[j].isChild) {
                  //console.log("Child index : ", j);
                  //console.log("index : ", index);
                  gridref.data.data[j].rowIndex = index;
                } else {
                  break;
                }
              }
            }
          }
          gridref.donotcallserver = true;
          table.clear().draw();
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}

export function cellClickAddPo(
  reference,
  gridRefFn,
  callbackAddPo,
  callbackPoDetails,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-plus",
  buttonClass2 = "fa-info"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      cellText = `${cellText} <button class="btn btn-sm btn-primary clickrenderer-po-info"><i class="fa ${buttonClass2}"></i></button>`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.clickrenderer-po-info",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));

          callbackPoDetails(row.data()).then((insertData) => {
            //table.clear().draw();
          });
        }
      );
    }
    // else {
    //     cellText = `${cellText}`;
    //     $(`#${settings.settings.sTableId}`).on('click', 'button.clickrenderer_3', function(ev){
    //         ev.stopImmediatePropagation();
    //         let el = $(this);
    //         let table = $(el.closest('table')).DataTable();
    //         let row = table.row(el.closest('tr'));
    //         if (confirm('Are you sure you want to create PO?')) {
    //             callbackAddPo(row.data()).then(insertData =>{
    //                 table.clear().draw();
    //             });
    //         } else {
    //             table.clear().draw();
    //         }
    //     });
    // }
    return cellText;
  };
  return returnFunc;
}

export function cellClickAcceptPO(
  reference,
  gridRefFn,
  callbackAcceptPo,
  callback,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-plus",
  buttonClass2 = "fa-info"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      //cellText = `<button class="btn greenbtn btntext btnAccept">Accept</button>`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnAccept",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackAcceptPo(row.data()).then((insertData) => {
            table.clear().draw();
          });
        }
      );

      if (row.supplier_count != undefined && row.supplier_count > 1) {
        if (row.last_po_status != undefined && row.last_po_status >= 0) {
          cellText = `<button class="btn btn-sm btn-primary clickrenderer ${
            row.isRendered ? "hide" : ""
          }"><i class="fa fa-arrow-down"></i></button>
                    <button class="btn btn-sm btn-primary clickrenderer2 ${
                      row.isRendered ? "" : "hide"
                    }"><i class="fa fa-arrow-up"></i></button>
                    ${cellText}`;
        } else {
          cellText = `${cellText}`;
        }
        $(`#${settings.settings.sTableId}`).on(
          "click",
          "button.clickrenderer",
          function (ev) {
            ev.stopImmediatePropagation();
            let el = $(this);
            let table = $(el.closest("table")).DataTable();
            let row = table.row(el.closest("tr"));
            let insertIndex = row.index() + 1;

            callback(row.data()).then((insertData) => {
              let gridref = reference.$refs[gridRefFn];
              gridref.data.draw = new Date().getTime();
              for (
                let insertCount = 0;
                insertCount < insertData.length;
                insertCount++
              ) {
                insertData[insertCount].isChild = true;
                insertData[insertCount].rowIndex = row.index();
                gridref.data.data.splice(
                  insertIndex + insertCount,
                  0,
                  insertData[insertCount]
                );
              }
              gridref.donotcallserver = true;
              table.clear().draw();
            });
          }
        );
        $(`#${settings.settings.sTableId}`).on(
          "click",
          "button.clickrenderer2",
          function (ev) {
            ev.stopImmediatePropagation();
            let el = $(this);
            let table = $(el.closest("table")).DataTable();
            let row = table.row(el.closest("tr"));
            let gridref = reference.$refs[gridRefFn];
            gridref.data.data[row.index()].isRendered = false;
            let insertIndex = row.index() + 1;

            let data = [];
            for (
              let rowCounter = 0;
              rowCounter < gridref.data.data.length;
              rowCounter++
            ) {
              if (
                rowCounter >= insertIndex &&
                gridref.data.data[rowCounter].isChild &&
                gridref.data.data[rowCounter].rowIndex == row.index()
              ) {
                continue;
              }
              data.push(gridref.data.data[rowCounter]);
            }
            gridref.data.draw = new Date().getTime();
            gridref.data.data = data;
            gridref.donotcallserver = true;
            table.clear().draw();
          }
        );
      }
    }
    return cellText;
  };
  return returnFunc;
}

export function cellClickSupplierPo(
  reference,
  gridRefFn,
  callbackConfirmPo,
  callbackAcceptPo,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-plus",
  buttonClass2 = "fa-info"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnConfirm",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackConfirmPo(row.data()).then((insertData) => {
            //table.clear().draw();
          });
        }
      );
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnAccept",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackAcceptPo(row.data()).then((insertData) => {
            table.clear().draw();
          });
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}

export function cellClickInvoice(
  reference,
  gridRefFn,
  callbackAddInvoice,
  callbackinvoiceDetails,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-plus",
  buttonClass2 = "fa-info"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      cellText = `<button class="btn btnDetails"><i class="fa fa-list" style="font-size: 14px;"></i></button>`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnDetails",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackinvoiceDetails(row.data()).then((insertData) => {
            //table.clear().draw();
          });
        }
      );
    } else {
      cellText = `<button class="btn btnCreate"><i class="fa fa-plus" style="font-size: 14px;"></i></button>`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnCreate",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackAddInvoice(row.data()).then((insertData) => {
            //table.clear().draw();
          });
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}

export function cellSimpleClickRenderer(
  reference,
  gridRefFn,
  eventClass,
  callback,
  renderDataFn
) {
  let returnFunc = function (data, type, row, settings) {
    let cellLabel = `${renderDataFn(data, type, row, settings)}`;
    let cellText = ``;
    if (
      row.is_visited == false &&
      window?.keycloakImplObj?.userDetails?.id == row.assigned_to
    ) {
      if (row.is_so_submitted) {
        let classCode = "btn-info-reverse";
        //cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        if (cellLabel == "Customer PO") {
          classCode = "btn-success-reverse";
          //cellText += `<i class="fa fa-circle-small text-danger" style="width: 6px; height: 6px; position: absolute; border: 1px solid red; border-radius: 50%; background: #ff5757;"></i>`;
        }
        cellText = `<span class="${classCode} ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        cellText += `</span>`;
      } else if (row.so_no) {
        let classCode = "btn-info-reverse";
        //cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        if (cellLabel == utils.getSONo(row.so_no)) {
          classCode = "btn-success-reverse";
          //cellText += `<i class="fa fa-circle-small text-danger" style="width: 6px; height: 6px; position: absolute; border: 1px solid red; border-radius: 50%; background: #ff5757;"></i>`;
        }
        cellText = `<span class="${classCode} ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        cellText += `</span>`;
      } else if (row.enquiry_no) {
        let classCode = "btn-info-reverse";
        //cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        if (cellLabel == utils.getENQNo(row.enquiry_no)) {
          classCode = "btn-success-reverse";
          //cellText += `<i class="fa fa-circle-small text-danger" style="width: 6px; height: 6px; position: absolute; border: 1px solid red; border-radius: 50%; background: #ff5757;"></i>`;
        }
        cellText = `<span class="${classCode} ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        cellText += `</span>`;
      } else if (row.qualified_no) {
        let classCode = "btn-info-reverse";
        //cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        if (cellLabel == utils.getQLNo(row.qualified_no)) {
          classCode = "btn-success-reverse";
          //cellText += `<i class="fa fa-circle-small text-danger" style="width: 6px; height: 6px; position: absolute; border: 1px solid red; border-radius: 50%; background: #ff5757;"></i>`;
        }
        cellText = `<span class="${classCode} ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        cellText += `</span>`;
      } else if (row.unqualified_no) {
        let classCode = "btn-info-reverse";
        //cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        if (cellLabel == utils.getUQLNo(row.unqualified_no)) {
          classCode = "btn-success-reverse";
          //cellText += `<i class="fa fa-circle-small text-danger" style="width: 6px; height: 6px; position: absolute; border: 1px solid red; border-radius: 50%; background: #ff5757;"></i>`;
        }
        cellText = `<span class="${classCode} ${eventClass}" style="cursor:pointer;">${cellLabel}`;
        cellText += `</span>`;
      }
    } else {
      cellText = `<span class="btn-info-reverse ${eventClass}" style="cursor:pointer;">${cellLabel}</span>`;
    }
    $(`#${settings.settings.sTableId}`).on(
      "click",
      `span.${eventClass}`,
      function (ev) {
        ev.stopImmediatePropagation();
        let el = $(this);
        let table = $(el.closest("table")).DataTable();
        let row = table.row(el.closest("tr"));
        callback(row.data());
      }
    );
    return cellText;
  };
  return returnFunc;
}

export function cellClickFullkitDate(
  reference,
  gridRefFn,
  callbackFullkitDate,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-plus",
  buttonClass2 = "fa-info"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnConfirm",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackFullkitDate(row.data()).then((insertData) => {
            table.clear().draw();
          });
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}

export function cellClickAssignedToNode(
  reference,
  gridRefFn,
  callbackFullkitDate,
  buttonConditionFn,
  renderDataFn
) {
  console.log("reference : ", reference.nodes);
  let returnFunc = function (data, type, row, settings) {
    console.log("data : ", data);
    console.log("type : ", type);
    console.log("row : ", row);
    console.log("settings : ", settings);
    let selected_node = null;
    let cellText = ``;

    cellText = `<select name="assign_to_node" class="form-control assigned_to_node_dropdown">`;
    cellText += `<option value="">--Select Node--</option>`;
    for (let node of reference.nodes) {
      let selectedClass = "";
      if (renderDataFn(data, type, row, settings) != null) {
        selected_node = renderDataFn(data, type, row, settings);
        if (node.id == selected_node) {
          selectedClass = "selected";
        }
      }
      cellText += `<option value="${node.id}" ${selectedClass}>${node.name} ( ${node.code} )</option>`;
    }
    cellText += `</select>`;
    if (buttonConditionFn(data, type, row, settings)) {
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.btnConfirm",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          callbackFullkitDate(row.data()).then((insertData) => {
            table.clear().draw();
          });
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}

export function cellClickRenderers(
  reference,
  gridRefFn,
  callback,
  buttonConditionFn,
  renderDataFn,
  buttonClass = "fa-arrow-down",
  buttonClass2 = "fa-arrow-up"
) {
  let returnFunc = function (data, type, row, settings) {
    let cellText = `${renderDataFn(data, type, row, settings)}`;
    if (buttonConditionFn(data, type, row, settings)) {
      cellText =
        `<button class="btn btn-sm btn-primary clickrenderer ${
          row.isRendered ? "hide" : ""
        }">` +
        row.po_number +
        `</button>${cellText}`;
      $(`#${settings.settings.sTableId}`).on(
        "click",
        "button.clickrenderer",
        function (ev) {
          ev.stopImmediatePropagation();
          let el = $(this);
          let table = $(el.closest("table")).DataTable();
          let row = table.row(el.closest("tr"));
          let insertIndex = row.index() + 1;

          callback(row.data()).then((insertData) => {
            let gridref = reference.$refs[gridRefFn];
            gridref.data.draw = new Date().getTime();
            let i = 0;
            // for(let insertCount = i ; insertCount < insertData.length ; insertCount++){
            //     insertData[insertCount].isChild = true;
            //     insertData[insertCount].rowIndex = row.index();
            //     //console.log("insertIndex+insertCount ; ", insertIndex+insertCount);
            //     //console.log("insertData[insertCount] : ", insertData[insertCount]);
            //     gridref.data.data.splice((insertIndex+insertCount), 0, insertData[insertCount]);
            // }
            // let index = 0;
            // for(let rowCounter = 0; rowCounter < gridref.data.data.length;rowCounter++){
            //     if(rowCounter != row.index() && gridref.data.data[rowCounter].isRendered){
            //         index = rowCounter;
            //         for(let j = (rowCounter + 1); j<gridref.data.data.length; j++){
            //             if(gridref.data.data[j].isChild){
            //                 //console.log("Child index : ", j);
            //                 //console.log("index : ", index);
            //                 gridref.data.data[j].rowIndex = index;
            //             } else {
            //                 break;
            //             }
            //         }
            //     }
            // }
            // gridref.donotcallserver = true;
            table.clear().draw();
          });

          /* let insertData = callback(row.data());
                let gridref = reference.$refs[gridRefFn];
                gridref.data.draw = new Date().getTime();
                for(let insertCount = 0 ; insertCount < insertData.length ; insertCount++){
                    gridref.data.data.splice((insertIndex+insertCount), 0, insertData[insertCount]);
                }
                gridref.donotcallserver = true;
                table.clear().draw(); */
        }
      );
    }
    return cellText;
  };
  return returnFunc;
}
