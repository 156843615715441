<template lang="html">
  <div>
    <div v-for="(node, index) in nodes" :key="index">
      <div class="node" :class="className">
        <!-- <CBadge :color="node.tags == 'location' ? 'info' : 'secondary'" class="mt-1 mb-1 ml-2"> -->
        <div
          class="node-row"
          :class="[
            activeName == node.id ? 'active' : '',
            node.tags == 'location' ? ' border-location' : '',
            node.tags == 'node' ? ' border-node' : '',
            node.tags == 'category' ? ' border-category' : '',
            node.tags == 'sku' ? ' border-sku' : '',
          ]"
        >
          <div
            class="node-action"
            v-if="node.nodes || node.tags == 'node'"
            @click="parentToggle(node)"
          >
            <i class="fa fa-minus-circle" v-if="node.isOpen"></i>
            <i class="fa fa-plus-circle" v-else></i>
          </div>
          <CInputCheckbox
            v-if="isCheckboxVisible"
            :disabled="disableControl"
            :checked="ids[node.id]"
            @change="onCheckBoxChange(node)"
          />
          <span class="node-title" @click="parentToggle(node)"
            >{{ node.text
            }}<sub
              :class="
                node.status_name == 'Active'
                  ? 'pl-2 text-success'
                  : 'pl-2 text-danger'
              "
              >({{ node.status_name }})</sub
            ></span
          >
          <!-- <sub :class="node.status_name=='ACTIVE' ? 'pl-2 text-success':'pl-2 text-danger'">{{ node.status_name }}</sub> -->
          <!-- <CButton size="sm" class="btn btn-sm"><i class="fa fa-trash"></i></CButton> -->
          <a class="addBtn" @click="onAdd(node)" v-if="node.tags != 'sku'"
            ><i class="text-warning fa fa-plus"></i
          ></a>
          <a class="editBtn" @click="onEdit(node)"
            ><i class="text-warning fa fa-pencil"></i
          ></a>
          <a class="editBtn" @click="onSideBar(node)" v-if="node.tags == 'node'"
            ><i class="text-warning fa fa-list"></i
          ></a>
        </div>
        <!-- </CBadge> -->
        <b-tree
          class="child-node"
          :isCheckboxVisible="false"
          :disableControl="true"
          :ids="ids"
          @checkChanged="updateids"
          @sideBar="onSideBar"
          @OnclickChild="toggle"
          v-on="$listeners"
          @editNode="onEdit"
          @addNode="onAdd"
          :nodes="node.nodes"
          v-if="node.isOpen && node.nodes && node.nodes.length > 0"
        ></b-tree>
      </div>
    </div>
    <!-- <CButton size="sm" class="btn btn-sm"><i class="fa fa-plus"></i> Add More</CButton> -->
  </div>
</template>

<script>
import * as nodeSkuApi from "@common/utils/api/masters/nodeskus.js";
import SkuListSideModal from "@common/components/tree/SkuListSidePanel.vue";
export default {
  name: "tree",
  components: { SkuListSideModal },
  props: {
    nodes: [],
    ids: {},
    disableControl: false,
    openSelected: false,
    isCheckboxVisible: true,
    className: "",
  },
  data() {
    return {
      activeName: "",
    };
  },
  watch: {
    ids: function (newVal, oldVal) {
      // watch it
      this.closeAll();
      this.checkOpenSelected();
    },
    nodes: function (newVal, oldVal) {
      console.log("Nodes Updates:", this.nodes);
    },
  },
  mounted() {
    this.checkOpenSelected();
  },
  methods: {
    checkOpenSelected() {
      if (this.openSelected) {
      }
    },
    parentToggle:function(node){
      node.isOpen = !node.isOpen;
      console.log("Emmited Display:",node);
   
    this.toggle(node);
    this.$emit("OnclickChild", node);

    },
    toggle: function (node) {
      // if(node.isOpen){

      // }
      //this.parentToggle();
      // console.log("Node on toggle Button 1 :", node.isOpen);
      // //node.isOpen = !node.isOpen;
      // console.log("Node on toggle Button 2 :", node.isOpen);

    
      if (node.isOpen && node.tags == "node") {
        nodeSkuApi
          .fetchSKUsByNodeId(node.id)
          .then((response) => {
            if (response.data.status.status_code === 0) {
              let skus = response.data.payload;
              let filteredSkus = skus.filter((item) => {
                return item.node == node.id;
              });
              let nodesArr = [];
              filteredSkus.forEach((element) => {
                let temp = {
                  checkable: true,
                  id: element.sku,
                  nodes: [],
                  parent: node.id,
                  tags: "sku",
                  text: element.skuDetails.name,
                };
                nodesArr.push(temp);
              });
              //node.nodes = nodesArr;
              console.log("SKUs : ", node.nodes);
              console.log("Filtered SKUs : ", filteredSkus);
            }
          })
          .catch((err) => {
            this.error = err?.response?.data?.payload
              ? err.response.data.payload
              : err.message;
            toasterService.error(err.message);
          });
      }
      this.$forceUpdate();
    },
    closeAll() {
      for (let node of this.nodes) {
        this.close(node);
      }
    },
    open: function (node) {
      node.isOpen = true;
      this.$forceUpdate();
    },
    close: function (node) {
      node.isOpen = false;
      this.$forceUpdate();
    },
    onCheckBoxChange(node) {
      this.ids[node.id] = !this.ids[node.id];
      this.$emit("checkChanged", [this.ids, node]);
      this.$forceUpdate();
    },
    updateids(details) {
      this.ids = details[0];
      this.$emit("checkChanged", details);
    },
    onAdd(node) {
      console.log("Node : ", node);
      let id = node.id;
      this.activeName = id;
      this.$emit("addNode", node);
    },
    onEdit(node) {
      console.log("Node : ", node);
      let id = node.id;
      this.activeName = id;
      this.$emit("editNode", node);
    },
    onSideBar(node) {
      console.log("Node at Side Bar: ", node);
      //this.$refs.skuListSideModal.show(node);
      this.$emit("sideBar", node);
    },
    editNode(node) {},
  },
};
</script>

<style>
.nodeUiClass {
  /* box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -webkit-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72);
    -moz-box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.72); */
  margin: 5px 0px;
}
.node {
  position: relative;
}
.node .child-node {
  margin-left: 25px;
  position: relative;
}
.node .child-node::after {
  content: " ";
  height: calc(100% - 5px);
  position: absolute;
  left: -20px;
  width: 0px;
  border: 1px solid;
  border-style: dotted;
  top: -5px;
}
.node .node-action {
  cursor: pointer;
  display: inline-block;
  padding-right: 5px;
}
.node .node-title {
  cursor: pointer;
  display: inline-block;
  padding-left: 5px;
  line-height: 28px;
  margin-left: -5px;
}
.child-node.hide {
  display: none;
}
.child-node .node::after {
  content: " ";
  height: 0;
  position: absolute;
  left: -18px;
  width: 10px;
  border: 1px solid;
  border-style: dotted;
  top: 12px;
}
.node .form-check {
  display: inline-block;
  vertical-align: middle;
  padding-right: 0px;
}
.node .form-check .node-title {
  padding-left: 0;
}
.node .hide-checkbox .form-check {
  display: none !important;
}

.node-row {
  display: inline-block;
  padding: 0px 5px;
  margin: 2px 0px;
  border-radius: 5px;
}
.node-row:active {
  background: aliceblue;
}
.border-location {
  /* background: #1f3863;
    color: #fff; */
  /* border: 2px solid #333; */
  background: #fff;
  color: #203864;
}
.border-node {
  /* background: #4da4f0;
    color: #000; */
  /* border: 2px solid #d65806; */
  background: #fff;
  color: #203864;
}
.border-category {
  /* border: 2px solid #3187f2; */
  /* background: #1f3863;
    color: #fff; */
  /* border: 1px solid #203864; */
  background: #fff;
  color: #203864;
}
.border-sku {
  /* border: 2px solid #d0d0d0; */
  background: #e0dcdc;
  color: #203864;
}
.addBtn {
  margin-left: 1rem;
  padding: 5px;
  cursor: pointer;
}
.editBtn {
  /* margin-left: .5rem; */
  padding: 5px;
  cursor: pointer;
}
</style>
