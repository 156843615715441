import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '@common/utils/constants.js'

function add(data) {				
    return APIBuilder.buildPostRequest(constants.LOCATION, data)
}

function getLocationNodes(locationIds){
    return APIBuilder.buildPostRequest(constants.LOCATION+"/nodes", locationIds);
}

function allLocationList () {
    return APIBuilder.buildGetRequest(constants.ALLLOCATIONS)
}
function allLocationListByStatus(status) {
    return APIBuilder.buildGetRequest(constants.ALLLOCATIONS  + "/status/" + status)
}

function fetchList(parentid) {
    if (parentid==null) {
        return APIBuilder.buildGetRequest(constants.LOCATIONS)
    }
    else {
        return APIBuilder.buildGetRequest(constants.LOCATIONS+"?parentid=" + parentid)
    }
}

function details(id) {
    return APIBuilder.buildGetRequest(constants.LOCATION + "/" + id)
}

function update(id, data) {
    return APIBuilder.buildPutRequest(constants.LOCATION + '/' + id, data)
}

function deleteLocation(id) {
    return APIBuilder.buildDeleteRequest(constants.LOCATION + '/' + id)
}

function markInactive(data) {
    return APIBuilder.buildPostRequest(constants.LOCATION+"/markInactive", data)
}

function markActive(data) {
    return APIBuilder.buildPostRequest(constants.LOCATION+"/markActive", data)
}

function markHold(data) {
    return APIBuilder.buildPostRequest(constants.LOCATION+"/markHold", data)
}

function markPhasedOut(data) {
    return APIBuilder.buildPostRequest(constants.LOCATION+"/markPhasedOut", data)
}

function uploadBulk(fd) {
    return APIBuilder.buildPostRequest(constants.LOCATION + '/bulk', fd)
}

function getAllLocations () {
    return APIBuilder.buildGetRequest(constants.ALLLOCATIONS + '/all')
}

export {
    add,
    fetchList,
    allLocationList,
    allLocationListByStatus,
    details,
    update,
    deleteLocation,
    getLocationNodes,
    markActive,
    markHold,
    markPhasedOut,
    markInactive,
    uploadBulk,
    getAllLocations
}